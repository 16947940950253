import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import * as moment from "moment";
import { getSortObj } from "common/lib/tableDataOptions";
import { getUNGMList as onGetUNGMList } from "slices/thunks";

const WBSanctionsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: any) => state.SanctionsList,
    (state) => ({
      ungmList: state.ungmList.data,
      meta: state.ungmList.meta,
      error: state.error,
      loading: state.loading,
    })
  );
  const { ungmList, meta, loading } = useSelector(selectProperties);

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      dispatch(
        onGetUNGMList({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $and: [
              {
                dataSource: {
                  $eq: "World Bank",
                },
              },

              {
                $or: [
                  {
                    companyName: {
                      $containsi: searchValue,
                    },
                  },
                ],
              },
            ],
          },
        })
      );
    },
    [dispatch, pagination.pageSize, searchParams, setSearchParams]
  );

  const columns = useMemo(
    () => [
      {
        header: "Contractor Name",
        accessorKey: "companyName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/sanctions-list/world-bank/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Country",
        accessorKey: "country",
        enableColumnFilter: false,
      },
      {
        header: "Sanction Start Date",
        accessorKey: "sanctionStartDate",
        id: "sanctionStartDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date =
            (cell.getValue() &&
              moment(new Date(cell?.getValue())).format("DD MMM Y")) ||
            "";
          return <>{date}</>;
        },
      },
      {
        header: "Sanction End Date",
        accessorKey: "sanctionEndDate",
        id: "sanctionEndDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date =
            (cell.getValue() &&
              moment(new Date(cell?.getValue())).format("DD MMM Y")) ||
            "";
          return <>{date}</>;
        },
      },
      {
        header: "Last Updated",
        accessorKey: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="World Bank Banned List"
            pageTitle="Sanctions List"
          />

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={9}>
                  <h5 className="card-title">World Bank Banned List</h5>
                  <p className="mb-0 text-muted">
                    This entity has been (or is), sanctioned by the World Bank
                    under their fraud and corruption policy. Prior to any
                    engagement with this entity, it is recommended that the
                    current status (santion) of this company/individual is
                    determined. Source:
                    <a
                      href="http://web.worldbank.org/external/default/main?theSitePK=84266&contentMDK=64069844&menuPK=116730&pagePK=64148989&piPK=64148984"
                      className="mx-1 text-primary"
                    >
                      World Bank Listing of Ineligible Firms & Individual.
                    </a>
                  </p>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search World Bank banned list..."
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPage(1);
                        }}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    {/* <button className="btn btn-success">
                      <i className="ri-equalizer-line align-bottom me-1"></i>
                      Filters
                    </button> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={ungmList || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WBSanctionsList;
