import React from "react";
import ImportokWizard from "@importok/react";
import style from "./import-styles";
import {
  importContractor,
  getContractors,
  getOrganizationTypes,
} from "common/services/contractors.service";
import { getCountries, getRegions } from "common/services/regions.service";
import { normalize } from "common/lib/normalize";
import ImportRecord from "@importok/javascript/ImportRecord";
import { Alert } from "reactstrap";

const ContractorsImport: React.FC<{}> = () => {
  const fields = {
    ContractorName: {
      label: "Contractor Name",
      transformers: "capitalize|trim",
      validators: "required",
    },
    Acronym: {
      label: "Acronym",
      validators: "",
      transformers: "uppercase|trim",
    },
    OrganizationType: {
      label: "Organization Type",
      validators: "required|in:organizationTypes",
      transformers: "trim",
      provider: "organizationTypes",
    },
    PhoneNumber: {
      label: "Phone Number",
      validators: "",
      transformers: "trim",
    },
    EmailAddress: {
      label: "Email Address",
      validators: "",
      transformers: "trim",
    },
    PostalAddress: {
      label: "Postal Address",
      validators: "",
      transformers: "trim",
    },
    Website: {
      label: "Website",
      validators: "",
      transformers: "trim",
    },
    PhysicalAddress: {
      label: "Physical Address",
      validators: "",
      transformers: "trim",
    },
    City: {
      label: "City",
      validators: "",
      transformers: "trim",
    },
    Country: {
      label: "Country",
      validators: "in:businessCountries",
      transformers: "",
      provider: "businessCountries",
    },
    BusinessOwner: {
      label: "Business Owner",
      validators: "",
      transformers: "trim",
    },
    OldNid: {
      label: "Old Nid",
      validators: "",
      transformers: "trim",
    },
    UNGMNumber: {
      label: "UNGM Number",
      validators: "",
      transformers: "trim",
    },
    UNPPNumber: {
      label: "UNPP Number",
      validators: "",
      transformers: "trim",
    },
    ContactPerson: {
      label: "Contact Person",
      validators: "",
      transformers: "trim",
    },
  };

  const transformers = {};

  const validators = {
    contractorName: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      const response: any = getContractors({
        filters: {
          contractorName: {
            $eqi: input,
          },
        },
        fields: ["id"],
      });

      const data = normalize(response.data);

      if (data && data.length > 0) {
        errorMessage = "Contractor already exists";
      }
      return errorMessage || true;
    },
  };

  const providers = {
    organizationTypes: {
      get: async function (query: any) {
        const response = await getOrganizationTypes({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getOrganizationTypes({
          filters: {
            name: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item?.id,
          label: item?.name || "",
        }));
      },
    },
    businessCountries: {
      get: async function (query: any) {
        const response = await getCountries({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getCountries({
          filters: {
            name: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item?.id,
          label: item?.name || "",
        }));
      },
    },
    businessLocations: {
      get: async function (query: any) {
        const response = await getRegions({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getRegions({
          filters: {
            name: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item?.id,
          label: item?.name || "",
        }));
      },
    },
  };
  const [importErrors, setImportErrors] = React.useState<string[]>([]);

  const saveRecord = async function (record: any, meta: any) {
    try {
      const response = await importContractor({
        data: {
          contractorName: record?.properties?.ContractorName,
          acronym: record?.properties?.Acronym || null,
          organizationType: record?.properties?.OrganizationType || null,
          phoneNumber: record?.properties?.PhoneNumber || null,
          emailAddress: record?.properties?.EmailAddress || null,
          postalAddress: record?.properties?.PostalAddress || null,
          website: record?.properties?.Website || null,
          physicalAddress: record?.properties?.PhysicalAddress || null,
          businessOwner: record?.properties?.BusinessOwner || null,
          country: record?.properties?.Country || null,
          city: record?.properties?.City || null,
          oldNid: record?.properties?.OldNid || null,
          ungmNumber: record?.properties?.UNGMNumber || null,
          unppNumber: record?.properties?.UNPPNumber || null,
          contactPerson: record?.properties?.ContactPerson || null,
        },
      });
      return Promise.resolve(response);
    } catch (error: any) {
      if (error?.response?.data?.error?.message) {
        setImportErrors((state) => {
          return [...state, error?.response?.data?.error?.message];
        });
      }
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  return (
    <>
      {importErrors.length > 0 && (
        <Alert color="danger" className="material-shadow">
          {importErrors.map((error: any) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      )}

      <ImportokWizard
        title="Import Contractors"
        fields={fields}
        transformers={transformers}
        validators={validators}
        providers={providers}
        onRecordReady={saveRecord}
        // @ts-ignore
        style={style}
      />
    </>
  );
};

export default ContractorsImport;
