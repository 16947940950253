import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const getLoggedInUserFromServer = async () => {
  const user = localStorage.getItem("user");
  if (user)
    await api.get(url.GET_USER_PROFILE, {}).then((response: any) => {
      return response;
    });

  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};
// Get User Profile
export const getUserProfile = (params: any) => {
  return api.get(url.GET_USER_PROFILE, params);
};

// Edit profile
export const postJwtProfile = (data: any) =>
  api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) =>
  api.update(url.POST_EDIT_PROFILE + "/" + data.id, data);

// Register Method
export const postJwtRegister = (url: string, data: any) => {
  return api.create(url, data);
};

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) =>
  api.create(url.POST_FORGET_PASSWORD, data);

export const postResetPassword = (data: any) =>
  api.create(url.POST_CHANGE_PASSWORD, data);

// postSocialLogin
export const postSocialLogin = (data: any) =>
  api.create(url.SOCIAL_LOGIN, data);

// Countries
export const getCountries = (params: any) => api.get(url.GET_COUNTRIES, params);

/* Roles */
export const getRoles = (params: any) => api.get(url.GET_ROLES, params);
export const getRole = (params: any) => api.get(url.GET_ROLE + "/" + params.id);

/* User */
export const sendPasswordResetLink = (payload: any) =>
  api.create(url.POST_FORGET_PASSWORD, payload);

export const getCountryUsers = (params: any) => api.get(url.GET_USERS, params);

export const getEUSanctionsList = (params: any) =>
  api.get(url.GET_EU_SANCTIONS_LIST, params);
export const getEUSanctionedItem = (id: any, params: any) =>
  api.get(url.GET_EU_SANCTIONED_ITEM + "/" + id, params);
export const getUSSanctionsList = (params: any) =>
  api.get(url.GET_US_SANCTIONS_LIST, params);
export const getUSSanctionedItem = (id: any, params: any) =>
  api.get(url.GET_US_SANCTIONED_ITEM + "/" + id, params);
export const getUKSanctionsList = (params: any) =>
  api.get(url.GET_UK_SANCTIONS_LIST, params);
export const getUKSanctionedItem = (id: any, params: any) =>
  api.get(url.GET_UK_SANCTIONED_ITEM + "/" + id, params);
export const getUNGMList = (params: any) => api.get(url.GET_UNGM_LIST, params);
export const getUNGMItem = (id: any, params: any) =>
  api.get(url.GET_UNGM_ITEM + "/" + id, params);
export const uploadUNGMList = (data: any) =>
  api.createAndUpload(url.UPLOAD_UNGM_LIST, data);

// Contracts
export const getCountryContracts = (params: any) =>
  api.get(url.GET_CONTRACTS, params);
export const getCountryContract = (id: any, params: any) =>
  api.get(url.GET_CONTRACT + "/" + id, params);
export const addCountryContract = (data: any) =>
  api.create(url.ADD_CONTRACT, data);
export const updateCountryContract = (data: any) =>
  api.update(url.UPDATE_CONTRACT + "/" + data.id, data);
export const deleteCountryContract = (data: any) =>
  api.delete(url.DELETE_CONTRACT + "/" + data.id, data);
export const getCountryContractsSummary = (params: any) =>
  api.get(url.GET_COUNTRY_CONTRACTS_SUMMARY, params);

export const getAgencyContracts = (params: any) =>
  api.get(url.GET_AGENCY_CONTRACTS, params);
export const getAgencyContract = (id: any, params: any) =>
  api.get(url.GET_AGENCY_CONTRACT + "/" + id, params);
export const addAgencyContract = (data: any) =>
  api.create(url.ADD_AGENCY_CONTRACT, data);
export const updateAgencyContract = (data: any) =>
  api.update(url.UPDATE_AGENCY_CONTRACT + "/" + data.id, data);
export const deleteAgencyContract = (data: any) =>
  api.delete(url.DELETE_AGENCY_CONTRACT + "/" + data.id, data);

// Contractors
export const getCountryContractors = (params: any) =>
  api.get(url.GET_CONTRACTORS, params);
export const getCountryContractor = (id: any, params: any) =>
  api.get(url.GET_CONTRACTOR + "/" + id, params);
export const addCountryContractor = (data: any) =>
  api.create(url.ADD_CONTRACTOR, data);
export const updateCountryContractor = (data: any) =>
  api.update(url.UPDATE_CONTRACTOR + "/" + data.id, data);
export const deleteCountryContractor = (data: any) =>
  api.delete(url.DELETE_CONTRACTOR + "/" + data.id, data);

export const getAgencyContractors = (params: any) =>
  api.get(url.GET_AGENCY_CONTRACTORS, params);
export const getAgencyContractor = (id: any, params: any) =>
  api.get(url.GET_AGENCY_CONTRACTOR + "/" + id, params);
export const addAgencyContractor = (data: any) =>
  api.create(url.ADD_AGENCY_CONTRACTOR, data);
export const updateAgencyContractor = (data: any) =>
  api.update(url.UPDATE_AGENCY_CONTRACTOR + "/" + data.id, data);
export const deleteAgencyContractor = (data: any) =>
  api.delete(url.DELETE_AGENCY_CONTRACTOR + "/" + data.id, data);

// Contract Rating
export const getContractRatings = (params: any) =>
  api.get(url.GET_CONTRACT_RATINGS, params);

// Risk Assessments
export const getRiskAssessments = (params: any) =>
  api.get(url.GET_RISK_ASSESSMENTS, params);
export const getRiskAssessment = (id: any, params: any) =>
  api.get(url.GET_RISK_ASSESSMENT + "/" + id, params);
export const addRiskAssessment = (data: any) =>
  api.createAndUpload(url.ADD_RISK_ASSESSMENT, data);
export const updateRiskAssessment = (data: any) =>
  api.update(url.UPDATE_RISK_ASSESSMENT + "/" + data.id, data);
export const deleteRiskAssessment = (data: any) =>
  api.delete(url.DELETE_RISK_ASSESSMENT + "/" + data.id, data);

// Capacity Assessments
export const getCapacityAssessments = (params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENTS, params);
export const getCapacityAssessment = (id: any, params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT + "/" + id, params);
export const addCapacityAssessment = (data: any) =>
  api.createAndUpload(url.ADD_CAPACITY_ASSESSMENT, data);
export const updateCapacityAssessment = (data: any) =>
  api.update(url.UPDATE_CAPACITY_ASSESSEMENT + "/" + data.id, data);
export const deleteCapacityAssessment = (data: any) =>
  api.delete(url.DELETE_CAPACITY_ASSESSMENT + "/" + data.id, data);

// Risk Ratings
export const getRiskRatings = (params: any) =>
  api.get(url.GET_RISK_RATINGS, params);
export const getRiskRating = (id: any, params: any) =>
  api.get(url.GET_RISK_RATING + "/" + id, params);
export const addRiskRating = (data: any) =>
  api.create(url.ADD_RISK_RATING, data);
export const updateRiskRating = (data: any) =>
  api.update(url.UPDATE_RISK_RATING + "/" + data.id, data);
export const deleteRiskRating = (data: any) =>
  api.delete(url.DELETE_RISK_RATING + "/" + data.id, data);

// PVRS
export const getPVRs = (params: any) => api.get(url.GET_PVRS, params);
export const getPVR = (id: any, params: any) =>
  api.get(url.GET_PVR + "/" + id, params);
export const addPVR = (data: any) => api.createAndUpload(url.ADD_PVR, data);
export const updatePVR = (data: any) =>
  api.update(url.UPDATE_PVR + "/" + data.id, data);
export const deletePVR = (data: any) =>
  api.delete(url.DELETE_PVR + "/" + data.id, data);

// Capacity Assessment Types
export const getCapacityAssessmentTypes = (params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT_TYPES, params);
export const getCapacityAssessmentType = (id: any, params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT_TYPE + "/" + id, params);
export const addCapacityAssessmentType = (data: any) =>
  api.create(url.ADD_CAPACITY_ASSESSMENT_TYPE, data);
export const updateCapacityAssessmentType = (data: any) =>
  api.update(url.UPDATE_CAPACITY_ASSESSMENT_TYPE + "/" + data.id, data);
export const deleteCapacityAssessmentType = (data: any) =>
  api.delete(url.DELETE_CAPACITY_ASSESSMENT_TYPE + "/" + data.id, data);

// Capacity Assessment Ratings
export const getCapacityAssessmentRatings = (params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT_RATINGS, params);
export const getCapacityAssessmentRating = (id: any, params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT_RATING + "/" + id, params);
export const addCapacityAssessmentRating = (data: any) =>
  api.create(url.ADD_CAPACITY_ASSESSMENT_RATING, data);
export const updateCapacityAssessmentRating = (data: any) =>
  api.update(url.UPDATE_CAPACITY_ASSESSMENT_RATING + "/" + data.id, data);
export const deleteCapacityAssessmentRating = (data: any) =>
  api.delete(url.DELETE_CAPACITY_ASSESSMENT_RATING + "/" + data.id, data);

// Organization Types
export const getOrganizationTypes = (params: any) =>
  api.get(url.GET_ORGANIZATION_TYPES, params);
export const getOrganizationType = (id: any, params: any) =>
  api.get(url.GET_ORGANIZATION_TYPE + "/" + id, params);
export const addOrganizationType = (data: any) =>
  api.create(url.ADD_ORGANIZATION_TYPE, data);
export const updateOrganizationType = (data: any) =>
  api.update(url.UPDATE_ORGANIZATION_TYPE + "/" + data.id, data);
export const deleteOrganizationType = (data: any) =>
  api.delete(url.DELETE_ORGANIZATION_TYPE + "/" + data.id, data);

// Search
export const getSearchResults = (params: any) =>
  api.get(url.FUZZY_SEARCH, params);

// Persons
export const getPersons = (params: any) => api.get(url.GET_PERSONS, params);
export const getCountryPerson = (id: any, params: any) =>
  api.get(url.GET_PERSON + "/" + id, params);
export const addCountryPerson = (data: any) => api.create(url.ADD_PERSON, data);
export const updateCountryPerson = (data: any) =>
  api.update(url.UPDATE_PERSON + "/" + data.id, data);
export const deleteCountryPerson = (data: any) =>
  api.delete(url.DELETE_PERSON + "/" + data.id, data);
