import React, { useMemo, useEffect, useState } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";

import { getCapacityAssessmentTypes } from "common/services/capacity-assessments.service";
import { normalize } from "common/lib/normalize";

const SelectCapacityAssessmentType = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: (loading: boolean) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.capacityAssessmentType &&
        validation.errors.capacityAssessmentType
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.capacityAssessmentType &&
          validation.errors.capacityAssessmentType
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getCapacityAssessmentTypes({
          pagination: {
            page: 1,
            pageSize: 100,
          },
        });

        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.name}`,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label htmlFor="capacityAssessmentType-field" className="form-label">
        Type of Assessment
      </Label>
      <Select
        name="capacityAssessmentType"
        id="capacityAssessmentType"
        value={
          validation.values.capacityAssessmentType.value
            ? validation.values.capacityAssessmentType
            : null
        }
        placeholder="Select capacity assessment type"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          validation.setFieldValue("capacityAssessmentType", value);
          validation.setFieldError("capacityAssessmentType", "");
          validation.setFieldValue("capacityAssessmentRating", null);
        }}
        options={options}
        onBlur={() =>
          validation.setFieldTouched("capacityAssessmentType", true)
        }
        className={
          validation.touched.capacityAssessmentType &&
          validation.errors.capacityAssessmentType
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>
        {validation.errors.capacityAssessmentType?.value}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectCapacityAssessmentType;
