const styles = `        
    .title {
      font-family: Nunito, sans-serif;
      font-weight: 600;
      color: #495057;
      font-size: 1.1rem;
      line-height: 1.5;
    }
    .subtitle {
      font-family: Nunito, sans-serif;
      font-size: 0.9rem;
      line-height: 1.25;
      color: #878a99;
      font-weight: 400;
    }
    #importok {
      font-family: Nunito, sans-serif;
    }
    #dropzone {
      height: auto;  
    }
    .button-primary {
      background-color: #3577f1;
      border-color: #3577f1;    
    }
    .button-primary:hover {
      background-color: #2859b5;
      border-color: #2859b5;
    }
    .button-link {
      color: #3577f1; 
    }
    .button-link:hover {
      color: #2859b5;
    }
    .progress-bar-buffer {
      background-color: #3577f1;
    }
    .progress-percentage {
      color: #3577f1;
    }
    .progress-bar {
      background-color: #eff2f7;
    }
    `;

export default styles;
