import DataImport from "pages/DataImport";
import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import dataImporterOptions from "common/constants/data-importers";

const defaultImporter = dataImporterOptions.find(
  (option: any) => option.value === "contracts"
);

const Import = () => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          tag="button"
          className="btn btn-light"
          id="dropdownMenuButton"
        >
          <i className="ri ri-more-fill"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              setIsImportModalOpen(true);
            }}
          >
            Import
          </DropdownItem>
          <DropdownItem>Export</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <DataImport
        toggle={() => setIsImportModalOpen(!isImportModalOpen)}
        isModalOpen={isImportModalOpen}
        defaultImporter={defaultImporter}
      />
    </>
  );
};

export default Import;
