import React, { useMemo } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import AliasNamesInput from "./AliasNamesInput";
import ContactDetailsInput from "./ContactDetailsInput";
import SelectTitle from "./SelectTitle";
import Select from "react-select/dist/declarations/src/Select";
import SelectGender from "./SelectGender";
import SelectNationalityAtBirth from "./SelectNationalityAtBirth";
import SelectCurrentNationality from "./SelectCurrentNationality";
import { useNavigate } from "react-router-dom";

const PersonsForm: React.FC<{
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading }) => {
  const navigate = useNavigate();
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col md={12} className="mb-3">
          <Card>
            {/* <CardHeader>
              <h4 className="card-title mb-0">Contractor Information</h4>
            </CardHeader> */}
            <CardBody>
              <Row>
                <Col md={3}>
                  <SelectTitle
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    First Name
                  </Label>
                  <Input
                    name="firstName"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter contractor name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                    invalid={
                      validation.touched.firstName &&
                      validation.errors.firstName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={3} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Middle Name
                  </Label>
                  <Input
                    name="middleName"
                    id="middleName"
                    className="form-control"
                    placeholder="Enter middle name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.middleName || ""}
                    invalid={
                      validation.touched.middleName &&
                      validation.errors.middleName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.middleName &&
                  validation.errors.middleName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.middleName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={3} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Surname
                  </Label>
                  <Input
                    name="surname"
                    id="surname"
                    className="form-control"
                    placeholder="Enter last name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.surname || ""}
                    invalid={
                      validation.touched.surname && validation.errors.surname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.surname && validation.errors.surname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.surname}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0"> Alias Name(s)</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={12} className="mb-3">
                  <AliasNamesInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={6} className="mb-3">
                  <SelectGender
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="dateOfBirth-field" className="form-label">
                    Date of Birth
                  </Label>
                  {/* @ts-ignore */}
                  <Flatpickr
                    name="dateOfBirth"
                    id="dateOfBirth-field"
                    className="form-control"
                    placeholder="Select start date"
                    options={{
                      enableTime: false,
                      altInput: false,
                      altFormat: "d M, Y",
                      dateFormat: "d M, Y",
                    }}
                    onChange={(e) => {
                      validation.setFieldValue("dateOfBirth", e[0]);
                    }}
                    value={validation.values.dateOfBirth || ""}
                  />
                  {validation.touched.dateOfBirth &&
                  validation.errors.dateOfBirth ? (
                    <FormFeedback type="invalid">
                      {validation.errors.dateOfBirth}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <SelectNationalityAtBirth
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectCurrentNationality
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Contact Details</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={12} className="mb-3">
                  <ContactDetailsInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="mb-1">
                <div className="hstack gap-2 justify-content-between">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </button>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        color="light"
                        size="sm"
                        style={{ marginRight: "8px", marginBottom: "-1px" }}
                      ></Spinner>
                    )}
                    {isUpdate ? "Update Person" : " Add Person"}
                  </button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default PersonsForm;
