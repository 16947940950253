import { createSlice } from "@reduxjs/toolkit";
import {
  getContracts,
  addContract,
  updateContract,
  deleteContract,
  getContractRatings,
  getContract,
  getContractsSummary,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  contracts: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  contractRatings: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  contractTypes: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  contract: {
    contractTitle: "",
    contractValue: "",
    startDate: "",
    endDate: "",
    contractNo: "",
    contractor: {},
    subContractedTo: {},
    contractRating: {},
    contractType: {},
    agency: {},
    isLTA: false,
  },
  contractsSummary: {
    totalContractValue: 0,
    totalActiveContractValue: 0,
    totalContracts: 0,
    totalActiveContracts: 0,
    totalInactiveContracts: 0,
  },
  distributionByAgency: {
    data: [],
  },
  contractTotals: {
    totalContractValue: 0,
    totalContracts: 0,
    totalActiveContracts: 0,
    totalInactiveContracts: 0,
    percentageActiveContracts: 0,
    totalContractors: 0,
  },
  distributionByRegion: {
    data: [],
  },
  distributionByRegionAndType: {
    data: [],
  },
  error: {},
  refreshList: false,
  loading: false,
};

const ContractSlice = createSlice({
  name: "ContractSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDistributionByAgency: (state, action) => {
      state.distributionByAgency.data = action.payload;
    },
    setContractTotals: (state, action) => {
      state.contractTotals = action.payload;
    },
    setDistributionByRegion: (state, action) => {
      state.distributionByRegion.data = action.payload;
    },
    setDistributionByRegionAndType: (state, action) => {
      state.distributionByRegionAndType.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContracts.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);
        state.contracts.data = data || state.contracts.data;
      }
      state.contracts.meta = action.payload?.meta || state.contracts.meta;
      state.loading = false;
    });
    builder.addCase(getContracts.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
    builder.addCase(getContract.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);

        state.contract = data || state.contract;
      }
      state.loading = false;
    });
    builder.addCase(addContract.fulfilled, () => {
      toast.success("Contract Added Successfully", { autoClose: 3000 });
    });
    builder.addCase(addContract.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Contract Creation Failed", {
        autoClose: 3000,
      });
    });
    builder.addCase(updateContract.fulfilled, () => {
      toast.success("Contract Updated Successfully", { autoClose: 3000 });
    });
    builder.addCase(updateContract.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Contract Update Failed", { autoClose: 3000 });
    });
    builder.addCase(deleteContract.fulfilled, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.success("Contract Deleted Successfully", { autoClose: 3000 });
    });
    builder.addCase(deleteContract.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
      toast.error("Contract Deletion Failed", { autoClose: 3000 });
    });

    // Contract Ratings
    builder.addCase(getContractRatings.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = action.payload?.data.map((item: any) => {
          return {
            ...item.attributes,
            id: item.id,
          };
        });
        state.contractRatings.data = data || state.contractRatings.data;
      }
      state.contractRatings.meta =
        action.payload?.meta || state.contractRatings.meta;
      state.loading = false;
    });

    builder.addCase(
      getContractsSummary.fulfilled,
      (state: any, action: any) => {
        if (action.payload) {
          const data: any = action.payload;

          state.contractsSummary = data || state.contractsSummary;
        }
      }
    );
  },
});

export const {
  setLoading,
  setDistributionByAgency,
  setContractTotals,
  setDistributionByRegion,
  setDistributionByRegionAndType,
} = ContractSlice.actions;

export default ContractSlice.reducer;
