import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

// Agencies

export const getAgencies = (params: any) => api.get(url.GET_AGENCIES, params);
export const addAgency = (data: any) => api.create(url.ADD_AGENCY, data);
export const updateAgency = (data: any) =>
  api.put(url.UPDATE_AGENCY + "/" + data.id, data);
export const deleteAgency = (data: any) =>
  api.delete(url.DELETE_AGENCY + "/" + data.id, data);
