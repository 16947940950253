import React, { useMemo, useEffect } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { getRiskRatings } from "common/services/risk-assessments.service";
import { normalize } from "common/lib/normalize";

const SelectOverallRatingFilter = ({
  filterValue,
  setFilterValue,
}: {
  filterValue: any;
  setFilterValue: (value: any) => void;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>([]);
  const dispatch: any = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getRiskRatings({});
        setData(normalize(response.data));
      } catch (err: any) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, setLoading]);

  const options = useMemo(() => {
    const mainOptions = data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
    mainOptions.unshift({ value: null, label: "None" });
    return mainOptions;
  }, [data]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "#ced4da",
      borderRadius: ".25rem",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#ced4da",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      borderColor: "#ced4da",
      borderRadius: ".25rem",
    }),
  };

  return (
    <Select
      name="overallRiskRating"
      id="overallRiskRating"
      value={filterValue?.overallRiskRating || null}
      placeholder="Select overall risk rating"
      onChange={(value: any) => {
        setFilterValue(value);
      }}
      options={options}
      isLoading={loading}
      styles={customStyles}
    />
  );
};

export default SelectOverallRatingFilter;
