import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";

import {
  FromCol,
  ToCol,
  DetailsCol,
  TransactionID,
  TypeCol,
  Status,
} from "./TransactionsCol";
import { createSelector } from "reselect";

const AllPeople = () => {
  const dispatch: any = useDispatch();

  useEffect(() => {}, [dispatch]);

  const data = [
    {
      icon: "ri-user-line",
      iconClass: "primary",
      fullName: "Ahmed Abdi",
      gender: "Male",
      flaggedStatus: "Semg Mention",
      affiliatedTo: "Director, Hormuud Telecom",
      noOfAgencies: 5,
      nationality: "Somali",
      time: "08:13 AM",
      date: "23 Dec 2020",
    },

    // Genrate ten unique and random Contractor data that belong to Somalia
    {
      icon: "ri-user-line",
      iconClass: "primary",
      fullName: "Abdirahman Ali",
      gender: "Male",
      flaggedStatus: "Flagged",
      affiliatedTo: "Manager, Hormuud Telecom",
      noOfAgencies: 5,
      nationality: "Somali",
      time: "08:13 AM",
      date: "23 Dec 2020",
    },
    {
      icon: "ri-user-line",
      iconClass: "primary",
      fullName: "Mohamed Hassan",
      gender: "Male",
      flaggedStatus: "Not Flagged",
      affiliatedTo: "Director, Hormuud Telecom",
      noOfAgencies: 5,
      nationality: "Somali",
      time: "08:13 AM",
      date: "23 Dec 2020",
    },
    {
      icon: "ri-user-line",
      iconClass: "primary",
      fullName: "Abdullahi Ahmed",
      gender: "Male",
      flaggedStatus: "Not Flagged",
      affiliatedTo: "Director, Hormuud Telecom",
      noOfAgencies: 5,
      nationality: "Somali",
      time: "08:13 AM",
      date: "23 Dec 2020",
    },
    {
      icon: "ri-user-line",
      iconClass: "primary",
      fullName: "Hassan Mohamed",
      gender: "Male",
      flaggedStatus: "Flagged",
      affiliatedTo: "Director, Hormuud Telecom",
      noOfAgencies: 5,
      nationality: "Somali",
      time: "08:13 AM",
      date: "23 Dec 2020",
    },
    {
      icon: "ri-user-line",
      iconClass: "primary",
      fullName: "Abdirahman Ali",
      gender: "Male",
      flaggedStatus: "Not Flagged",
      affiliatedTo: "Director, Hormuud Telecom",
      noOfAgencies: 5,
      nationality: "Somali",
      time: "08:13 AM",
      date: "23 Dec 2020",
    },
    {
      icon: "ri-user-line",
      iconClass: "primary",
      fullName: "Hussien Ahmed",
      gender: "Male",
      flaggedStatus: "Flagged",
      affiliatedTo: "Director, Hormuud Telecom",
      noOfAgencies: 5,
      nationality: "Somali",
      time: "08:13 AM",
      date: "23 Dec 2020",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: "#",
        cell: (cell: any) => (
          <>
            <div className="avatar-xs">
              <div
                className={
                  "avatar-title bg-" +
                  cell.row.original.iconClass +
                  "-subtle text-" +
                  cell.row.original.iconClass +
                  " rounded-circle fs-16"
                }
              >
                <i className={cell.row.original.icon}></i>
              </div>
            </div>
          </>
        ),
      },

      {
        header: "Full Name",
        accessorKey: "fullName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <FromCol {...cell} />;
        },
      },
      {
        header: "Gender",
        accessorKey: "gender",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <ToCol {...cell} />;
        },
      },
      {
        header: "Flag Status",
        accessorKey: "flaggedStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <Status {...cell} />;
        },
      },
      {
        header: "Affiliated To",
        accessorKey: "affiliatedTo",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <TransactionID {...cell} />;
        },
      },
      // {
      //   header: "No of Agencies",
      //   accessorKey: "noOfAgencies",
      //   enableColumnFilter: false,
      //   cell: (cell: any) => {
      //     return <TypeCol {...cell} />;
      //   },
      // },
      {
        header: "Nationality",
        accessorKey: "nationality",
        enableColumnFilter: false,
      },
      {
        header: "Date Added",
        accessorKey: "time",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.row.original.date}{" "}
            <small className="text-muted">{cell.getValue()}</small>
          </>
        ),
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row className="g-3">
            <Col xxl={4} lg={6}>
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search people..."
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </Col>
            <Col xxl={2} lg={6}>
              <select
                className="form-control"
                data-choices
                data-choices-search-false
                name="choices-single-default2"
                id="choices-single-default2"
                onChange={(e) => {}}
              >
                <option value="">Select Gender</option>
                <option value="Trading ICOs">Male</option>
                <option value="Trading ICOs">Female</option>
              </select>
            </Col>
            <Col xxl={2} lg={6}>
              <select
                className="form-control"
                data-choices
                data-choices-search-false
                name="choices-single-default2"
                id="choices-single-default2"
                onChange={(e) => {}}
              >
                <option value="">Select Status</option>
                <option value="Active ICOs">Flagged</option>
                <option value="Ended ICOs">Not Flagged</option>
                <option value="Upcoming ICOs">Under Inquiry</option>
              </select>
            </Col>
            {/* <Col xxl={2} lg={6}>
              <select
                className="form-control"
                data-choices
                data-choices-search-false
                name="choices-single-default"
                id="choices-single-default"
                onChange={(e) => {}}
              >
                <option value="">Select Number of Agencies </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </Col> */}
            <Col xxl={2} lg={4}>
              <button className="btn btn-primary w-100" disabled>
                <i className="ri-equalizer-line align-bottom me-1"></i> Filters
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">People</h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2"></div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            loading={false}
            columns={columns}
            data={[]}
            pagination={{}}
            onPageChange={() => {}}
            FilterSection={() => {}}
            totalRows={0}
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light table-nowrap"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AllPeople;
