import BreadCrumb from "Components/Common/BreadCrumb";
import React, { useState } from "react";
import { Container } from "reactstrap";
import ContractorForm from "./ContractorForm";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addContractor as onAddContractor } from "../../slices/thunks";
import { useNavigate } from "react-router-dom";
import { LEVEL_COUNTRY } from "common/constants";

const AddContractor = () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      contractorName: "",
      organizationType: {
        value: "",
        label: "",
      },
      acronym: "",
      businessOwner: "",
      previousOrAliasName: [""],
      phoneNumber: [""],
      emailAddress: [""],
      website: "",
      physicalAddress: [
        {
          city: "",
          country: "",
          address: "",
        },
      ],
      postalAddress: [
        {
          city: "",
          country: "",
          address: "",
        },
      ],
      contactPersons: [
        {
          name: "",
        },
      ],
      contractorRegistration: [
        {
          registrationName: "",
          registrationType: "",
          registrationNumber: "",
          lengthOfValidity: "",
          countryOfRegistration: "",
          registrationDate: "",
        },
      ],
    },
    validationSchema: Yup.object({
      contractorName: Yup.string().required("Please enter contractor name"),
      acronym: Yup.string().nullable(),
      businessOwner: Yup.string().nullable(),
      organizationType: Yup.object().shape({
        value: Yup.number().required("Please select organization type"),
      }),
      previousOrAliasName: Yup.array().of(Yup.string()),
      phoneNumber: Yup.array().of(Yup.string()),
      emailAddress: Yup.array().of(Yup.string()),
      website: Yup.string().nullable(),
      physicalAddress: Yup.array().of(
        Yup.object({
          city: Yup.string(),
          country: Yup.string(),
          address: Yup.string(),
        })
      ),
      postalAddress: Yup.array().of(
        Yup.object({
          city: Yup.string(),
          country: Yup.string(),
          address: Yup.string(),
        })
      ),
      contactPersons: Yup.array().of(
        Yup.object({
          name: Yup.string(),
        })
      ),
      contractorRegistration: Yup.array().of(
        Yup.object({
          registrationName: Yup.string(),
          registrationType: Yup.string(),
          registrationNumber: Yup.string(),
          lengthOfValidity: Yup.string(),
          countryOfRegistration: Yup.string(),
          registrationDate: Yup.string(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      // Remove empty fields from physicalAddress, postalAddress, contractorRegistration, contact persons
      values["physicalAddress"] = values["physicalAddress"].filter(
        (el: any) => el.city !== "" || el.country !== "" || el.address !== ""
      );
      values["postalAddress"] = values["postalAddress"].filter(
        (el: any) => el.city !== "" || el.country !== "" || el.address !== ""
      );
      values["contractorRegistration"] = values[
        "contractorRegistration"
      ].filter(
        (el: any) =>
          el.registrationName !== "" ||
          el.registrationType !== "" ||
          el.registrationNumber !== "" ||
          el.countryOfRegistration !== ""
      );
      values["contactPersons"] = values["contactPersons"].filter(
        (el: any) => el.name !== ""
      );
      const data = {
        data: {
          contractorName: values["contractorName"],
          acronym: values["acronym"],
          organizationType: values["organizationType"]["value"] || null,
          previousOrAliasName:
            Array.isArray(values["previousOrAliasName"]) &&
            values["previousOrAliasName"].length > 0
              ? values["previousOrAliasName"]
                  .map((el) => {
                    if (el !== "") {
                      return {
                        name: el,
                      };
                    }
                    return null;
                  })
                  .filter((el) => el !== null)
              : null,
          phoneNumber:
            Array.isArray(values["phoneNumber"]) &&
            values["phoneNumber"].length > 0
              ? values["phoneNumber"]
                  .map((el) => {
                    if (el !== "") {
                      return {
                        name: el,
                      };
                    }
                    return null;
                  })
                  .filter((el) => el !== null)
              : null,
          emailAddress:
            Array.isArray(values["emailAddress"]) &&
            values["emailAddress"].length > 0
              ? values["emailAddress"]
                  .map((el) => {
                    if (el !== "") {
                      return {
                        name: el,
                      };
                    }
                    return null;
                  })
                  .filter((el) => el !== null)
              : null,
          website: values["website"],
          physicalAddress: values["physicalAddress"],
          postalAddress: values["postalAddress"],
          contactPersons: values["contactPersons"],
          contractorRegistration: values["contractorRegistration"],
        },
      };
      setLoading(true);
      dispatch(
        onAddContractor({
          level: LEVEL_COUNTRY,
          params: data,
        })
      ).then((result: any) => {
        setLoading(false);
        if (result?.payload?.data) {
          navigate("/contractors/" + result?.payload?.data?.id);
        }
      });
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Add Contractor" pageTitle="Contractors" />

        <ContractorForm
          validation={validation}
          isUpdate={false}
          loading={loading}
          setLoading={setLoading}
        />
      </Container>
    </div>
  );
};

export default AddContractor;
