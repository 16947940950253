import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateContract } from "common/services/contracts.service";
import ContractForm from "./ContractForm";
import { toast } from "react-toastify";

const UpdateContract: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchContracts: () => void;
  selectedRecord: any;
}> = ({ toggle, isModalOpen, fetchContracts, selectedRecord }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      contractTitle: selectedRecord?.contractTitle,
      contractNo: selectedRecord?.contractNo,
      contractor: {
        value: selectedRecord?.contractor?.id,
        label: `${selectedRecord?.contractor?.contractorName} ( ${selectedRecord?.contractor?.acronym} )`,
      },
      subContractedTo: {
        value: selectedRecord?.subContractedTo?.id,
        label: `${selectedRecord?.subContractedTo?.contractorName} ( ${selectedRecord?.subContractedTo?.acronym} )`,
      },
      contractRating: {
        value: selectedRecord?.contractRating?.id,
        label: selectedRecord?.contractRating?.name,
      },
      contractType: {
        value: selectedRecord?.contractType?.id,
        label: selectedRecord?.contractType?.name,
      },
      contractValue: selectedRecord?.contractValue,
      startDate: selectedRecord?.startDate,
      endDate: selectedRecord?.endDate,
      isLTA: selectedRecord?.isLTA,
      agency: {
        value: selectedRecord?.agency?.id,
        label: selectedRecord?.agency?.name,
      },

      regions:
        (selectedRecord?.location &&
          selectedRecord?.location.map((el: any) => {
            return {
              value: el?.id,
              label: el?.name,
            };
          })) ||
        [],
    },
    validationSchema: Yup.object({
      contractTitle: Yup.string().required("Please enter contract title"),
      contractNo: Yup.string().required("Please enter contract number"),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      subContractedTo: Yup.object().shape({
        value: Yup.number().nullable(),
      }),
      contractRating: Yup.object().shape({
        value: Yup.number().nullable(),
      }),
      contractType: Yup.object().shape({
        value: Yup.number().required("Please select contract type"),
      }),
      contractValue: Yup.number().required("Please enter contract value"),
      startDate: Yup.string(),
      endDate: Yup.string(),
      agency: Yup.object().shape({
        value: Yup.number().required("Please select agency"),
      }),
      regions: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required("Country is required"),
          value: Yup.string().required("Country is required"),
        })
      ),
    }),
    onSubmit: async (values: any) => {
      const data = {
        id: selectedRecord?.id,
        data: {
          contractTitle: values["contractTitle"],
          contractor: values["contractor"]["value"],
          contractNo: values["contractNo"],
          subContractedTo: values["subContractedTo"]["value"],
          contractRating: values["contractRating"]["value"],
          contractType: values["contractType"]["value"],
          contractValue: values["contractValue"],
          startDate: values["startDate"],
          endDate: values["endDate"],
          isLTA: values["isLTA"],
          agency: values["agency"]["value"],
          location: values["regions"].map((el: any) => el.value),
        },
      };

      setLoading(true);
      try {
        await updateContract(selectedRecord?.id, data);
        fetchContracts();
        validation.resetForm();
        toggle();
        toast.success("Contract updated successfully", {});
      } catch (e: any) {
        toast.error("Contract update failed", {});
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contract
      </ModalHeader>

      <ContractForm
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateContract;
