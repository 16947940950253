import React from "react";
import ImportokWizard from "@importok/react";
import {
  importContract,
  getContracts,
  getContractTypes,
} from "common/services/contracts.service";
import { getContractors } from "common/services/contractors.service";
import { normalize } from "common/lib/normalize";
import { getAgencies } from "common/services/agencies.service";
import { getRegions } from "common/services/regions.service";
import * as moment from "moment";
import ImportRecord from "@importok/javascript/ImportRecord";

import style from "./import-styles";
import { isNumeric } from "common/lib/numbers";
import { Alert } from "reactstrap";

const ContractsImport: React.FC<{}> = () => {
  const fields = {
    ContractTitle: {
      label: "Contract Title",
      validators: "required|contractTitle",
      transformers: "capitalize|trim",
    },
    ContractorName: {
      label: "Contractor",
      validators: "in:contractors|required",
      transformers: "trim",
      provider: "contractors",
    },
    ContractNo: {
      label: "Contract No",
      validators: "required|contractNo",
      transformers: "capitalize|trim",
    },
    CeilingContractValue: {
      label: "Ceiling Contract Value",
      validators: "required|number",
      transformers: "trim",
    },
    StartDate: {
      label: "Start Date",
      validators: "date:dd/mm/yyyy",
      transformers: "trim",
    },
    EndDate: {
      label: "End Date",
      validators: "date:dd/mm/yyyy|required",
      transformers: "trim",
    },
    ContractType: {
      label: "Contract Type",
      validators: "required|in:contractTypes",
      transformers: "trim",
      provider: "contractTypes",
    },
    Agency: {
      label: "Agency",
      validators: "required|in:agencies",
      transformers: "trim",
      provider: "agencies",
    },
    Location: {
      label: "Location",
      validators: "in:regions",
      transformers: "trim",
      provider: "regions",
    },
  };

  const transformers = {};

  const validators = {
    contractTitle: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      return getContracts({
        filters: {
          contractTitle: {
            $eqi: input,
          },
        },
        fields: ["id"],
      }).then((response: any) => {
        const data = normalize(response.data);

        if (data && data.length > 0) {
          errorMessage = "Contract title already exists";
        }
        return errorMessage || true;
      });
    },
    contractNo: function (record: ImportRecord, field: string) {
      const input = record.get(field);

      let errorMessage = "";
      return getContracts({
        filters: {
          contractNo: {
            $eqi: input,
          },
        },
        fields: ["id"],
      }).then((response: any) => {
        const data = normalize(response.data);

        if (data && data.length > 0) {
          errorMessage = "Contract number already exists";
        }
        return errorMessage || true;
      });
    },
  };

  const providers = {
    contractors: {
      get: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $eqi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.contractorName,
        };
      },
      find: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.id,
          label: item.contractorName,
        }));
      },
    },
    contractTypes: {
      get: async function (query: any) {
        const response = await getContractTypes({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getContractTypes({
          filters: {
            name: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item?.id,
          label: item?.name || "",
        }));
      },
    },
    agencies: {
      get: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: {
              $containsi: query,
            },
            category: {
              $null: false,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
      },
    },
    regions: {
      get: async function (query: any) {
        const splitQueries = query
          .split("|")
          .map((item: string) => item.trim());

        let data: any[] = [];
        for (let splitQuery of splitQueries) {
          const response = await getRegions({
            filters: {
              name: {
                $eqi: splitQuery,
              },
            },
          });

          if (!response.data) {
            throw new Error(`Response status ${response.status}`);
          }

          // data = normalize(response.data);
          data = [...data, ...normalize(response.data)];
          if (data.length === 0) {
            throw new Error(`Response status ${response.status}`);
          }
        }

        const label = data
          .map((item: any) => {
            return item?.name;
          })
          .join("|");
        const value = data
          .map((item: any) => {
            return item?.id;
          })
          .join("|");

        return {
          value: value,
          label: label,
        };
      },
      find: async function (query: any) {
        const splitQuery = query.split("|").map((item: string) => item.trim());

        //generate query for each splitQuery
        const orQuery = splitQuery.map((item: string) => {
          return {
            name: {
              $containsi: item,
            },
          };
        });

        const response = await getRegions({
          filters: {
            $or: orQuery,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }
        const newValues = data.filter((item: any) => {
          if (item.name && splitQuery.includes(item.name)) {
            return false;
          }
          return true;
        });

        const existingValues = data.filter((item: any) => {
          if (item.name && splitQuery.includes(item.name)) {
            return true;
          }
          return false;
        });

        return newValues.map((item: any) => {
          const pre = existingValues.map((item: any) => item?.name).join("|");
          const preIds = existingValues.map((item: any) => item?.id).join("|");

          return {
            value: `${(preIds && preIds + "|" + item.id) || item.id}`,
            label: `${(pre && pre + "|" + item.name) || item.name}`,
          };
        });
      },
    },
  };
  const [importErrors, setImportErrors] = React.useState<string[]>([]);
  const saveRecord = async function (record: any, meta: any) {
    let locations = [];
    if (
      record?.properties?.Location &&
      isNumeric(record?.properties?.Location)
    ) {
      locations = [parseInt(record?.properties?.Location)];
    } else {
      if (record?.properties?.location) {
        const splitLocation = record?.properties?.location.split("|");

        if (splitLocation.length > 1) {
          for (let i = 0; i < splitLocation.length; i++) {
            const response = await getRegions({
              filters: {
                name: {
                  $eqi: splitLocation[i],
                },
              },
            });

            const data = normalize(response.data);
            if (data.length > 0) {
              locations.push(data[0].id);
            }
          }
        }
      }
    }

    try {
      const response = await importContract({
        data: {
          contractTitle: record?.properties?.ContractTitle,
          contractor: record?.properties?.ContractorName,
          contractNo: record?.properties?.ContractNo,
          contractValue: record?.properties?.CeilingContractValue,
          startDate:
            (record?.properties?.StartDate &&
              moment(record?.properties?.StartDate, "DD/MM/YYYY").toDate()) ||
            null,
          endDate:
            (record?.properties?.EndDate &&
              moment(record?.properties?.EndDate, "DD/MM/YYYY").toDate()) ||
            null,
          contractType: record?.properties?.ContractType,
          agency: record?.properties?.Agency,
          location: record?.properties?.Location,
        },
      });
      return Promise.resolve(response);
    } catch (error: any) {
      if (error?.response?.data?.error?.message) {
        setImportErrors((state) => {
          return [...state, error?.response?.data?.error?.message];
        });
      }
      return Promise.reject(error);
    }
  };

  console.log("importErrors", importErrors);

  return (
    <>
      {importErrors.length > 0 && (
        <Alert color="danger" className="material-shadow">
          {importErrors.map((error: any) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      )}

      <ImportokWizard
        title="Import Contracts"
        fields={fields}
        transformers={transformers}
        // @ts-ignore
        validators={validators}
        providers={providers}
        onRecordReady={saveRecord}
        style={style}
      />
    </>
  );
};

export default ContractsImport;
