import React from "react";
import {
  Input,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Card,
  Button,
} from "reactstrap";

interface ContactPersonsInputProps {
  validation: any; // Replace 'any' with the appropriate type for the 'validation' parameter
}

const ContactPersonsInput = ({ validation }: ContactPersonsInputProps) => {
  return (
    <>
      <Card className="bg-light">
        <CardBody>
          {validation.values.contactPersons.map(
            (contactPerson: any, index: number) => {
              return (
                <div key={index} className="my-2">
                  <Row>
                    <Col xl={11} className="mb-3 mb-xl-0">
                      <Input
                        name={`contactPersons[${index}].name`}
                        id={`name-${index}`}
                        className="form-control"
                        placeholder="Enter name of contact person"
                        type="text"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={contactPerson.name || ""}
                        invalid={
                          validation.touched.contactPersons?.[index]?.name &&
                          validation.errors.contactPersons?.[index]?.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contactPersons?.[index]?.name &&
                        validation.errors.contactPersons?.[index]?.name && (
                          <FormFeedback type="invalid">
                            {validation.errors.contactPersons?.[index]?.name}
                          </FormFeedback>
                        )}
                    </Col>

                    <Col xl={1}>
                      <Button
                        className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none w-100 h-100"
                        onClick={() => {
                          validation.setFieldValue(
                            "contactPersons",
                            validation.values.contactPersons.filter(
                              (_: any, contactPersonIndex: number) =>
                                contactPersonIndex !== index
                            )
                          );
                        }}
                      >
                        {/* <i className="ri-delete-bin-fill me-2"></i> */}
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            }
          )}
        </CardBody>
      </Card>
      <button
        className="btn btn-soft-secondary  waves-effect waves-light material-shadow-none"
        disabled={validation.values.contactPersons.length === 3}
        onClick={(e) => {
          e.preventDefault();
          if (validation.values.contactPersons.length < 3) {
            const newContactPerson = {
              name: "",
            };
            validation.setFieldValue("contactPersons", [
              ...validation.values.contactPersons,
              newContactPerson,
            ]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default ContactPersonsInput;
