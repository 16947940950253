import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import TableContainer from "../../Components/Common/TableContainer";
import { getRegions, deleteRegion } from "common/services/regions.service";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import Permission from "Components/Common/Permission";
import LevelFilter from "./LevelFilter";
import CountryFilter from "./CountryFilter";
import DeleteModal from "Components/Common/DeleteModal";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";
import AddRegion from "./AddRegion";
import UpdateRegion from "./UpdateRegion";
import BreadCrumb from "Components/Common/BreadCrumb";

interface FilterValue {
  level: string;
  country: string;
}

// @ts-ignore
const TextWrapper: React.FC<{ value: string }> = ({ value }) => {
  if (!value) return null;
  const matches = value.match(/.{1,40}/g);
  const wrappedText = matches ? matches.join("\n") : ""; // Handle null case
  return (
    <div style={{ whiteSpace: "pre-wrap", width: "220px" }}>{wrappedText}</div>
  );
};

const Regions = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const fetchData = () => {
    setPage(1);
    setSorted([{ id: "createdAt", desc: true }]);
    setSearchValue("");
    setFilterValue(defaultFilterValue);
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (data: any) => {
    setSelectedRecord(data);
    setIsUpdateModalOpen(true);
  };

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    level: "",
    country: "",
  };

  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: any;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions = [];

      if (filterValue?.level) {
        andConditions.push({
          level: {
            $eq: filterValue.level,
          },
        });
      }
      if (filterValue?.country) {
        andConditions.push({
          country: {
            id: {
              $eq: filterValue.country?.value || null,
            },
          },
        });
      }

      andConditions.push({
        $or: [
          {
            name: {
              $containsi: searchValue,
            },
          },
          {
            adminLevel0: {
              name: {
                $containsi: searchValue,
              },
            },
          },
          {
            adminLevel1: {
              name: {
                $containsi: searchValue,
              },
            },
          },
        ],
      });

      try {
        setLoading(true);

        const response: any = await getRegions({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [
            "adminLevel0",
            "adminLevel1",
            "adminLevel1.adminLevel0",
            "adminLevel0.country",
            "adminLevel1.adminLevel0.country",
            "country",
          ],
          filters: {
            $and: andConditions,
          },
        });
        setData(normalize(response.data));
        setMeta(response?.meta);
      } catch (e: any) {
        toast.error("Error while fetching data", {});
      } finally {
        setLoading(false);
      }
    },
    [pagination.pageSize, searchParams, setSearchParams]
  );

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = useCallback(
    (data: any) => {
      setSelectedRecord(data);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedData = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, sorted, searchValue]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteRegion(selectedRecord.id);
        fetchUpdatedData();
        setDeleting(false);
        setDeleteModal(false);
        toast.success("Region deleted successfully");
      } catch (e: any) {
        toast.error("Error while deleting region");
      } finally {
        setDeleting(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Region Name",
        accessorKey: "name",
        id: "name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "200px" }}>
              <Link to={`#`} className="fw-medium link-primary">
                {cell.getValue()}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Adm Level",
        accessorKey: "level",
        id: "level",
        enableColumnFilter: false,
      },
      {
        header: "Admin Level 0",
        accessorKey: "adminLevel0.name",
        enableColumnFilter: false,
        id: "adminLevel0.name",
        cell: (cell: any) => {
          const rowData = cell.row.original;
          if (rowData.level <= 1) {
            return <TextWrapper value={cell.getValue() || ""} />;
          } else {
            return (
              <TextWrapper
                value={rowData.adminLevel1?.adminLevel0?.name || ""}
              />
            );
          }
        },
      },
      {
        header: "Admin Level 1",
        accessorKey: "adminLevel1.name",
        enableColumnF1lter: false,
        id: "adminLevel1.name",
        cell: (cell: any) => <TextWrapper value={cell.getValue() || ""} />,
      },
      {
        header: "Country",
        accessorKey: "country.name",
        enableColumnF1lter: false,
        id: "country.name",
        cell: (cell: any) => {
          const rowData = cell.row.original;
          if (rowData.level === 0) {
            return <TextWrapper value={cell.getValue() || ""} />;
          } else if (rowData.level === 1) {
            return (
              <TextWrapper value={rowData.adminLevel0?.country?.name || ""} />
            );
          } else {
            return (
              <TextWrapper
                value={rowData.adminLevel1?.adminLevel0?.country?.name || ""}
              />
            );
          }
        },
      },
      {
        header: "X Coord",
        accessorKey: "x_coord",
        enableColumnFilter: false,
        id: "x_coord",
      },

      {
        header: "Y Coord",
        accessorKey: "y_coord",
        enableColumnFilter: false,
        id: "y_coord",
      },

      {
        header: "Action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission resource="region" action="update">
                  <DropdownItem
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      showUpdateModalForm({
                        id: rowData.id,
                        ...rowData,
                      });
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </DropdownItem>
                </Permission>

                <Permission resource="region" action="delete">
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [onClickDelete]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Regions"
            pageTitles={[{ title: "Dashboard", link: "/dashboard" }]}
          />

          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => handleDelete()}
            onCloseClick={() => setDeleteModal(false)}
            loading={deleting}
          />
          <Card>
            <CardBody>
              <Row className="g-3">
                <Col xxl={6} lg={6}>
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search regions..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        setPage(1);
                      }}
                      value={searchValue}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col xxl={2} lg={6}>
                  <LevelFilter
                    onChange={(value: any) => {
                      setFilterValue({ ...filterValue, level: value });
                      setPage(1);
                    }}
                    value={filterValue.level}
                  />
                </Col>

                <Col xxl={2} lg={6}>
                  <CountryFilter
                    onChange={(value: any) => {
                      setFilterValue({ ...filterValue, country: value });
                      setPage(1);
                    }}
                    value={filterValue.country}
                  />
                </Col>
                <Col xxl={2} lg={4}>
                  <button
                    onClick={() => {
                      setFilterValue(defaultFilterValue);
                      setSearchValue("");
                      setPage(1);
                      setSorted([{ id: "createdAt", desc: true }]);
                    }}
                    className="btn btn-outline-primary material-shadow-none w-100"
                  >
                    <i className="ri-equalizer-line align-bottom me-1"></i>{" "}
                    Reset Filters
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={3}>
                  <h5 className="card-title mb-0">Regions</h5>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <Permission resource="region" action="create">
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          showAddModalForm();
                        }}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add Region
                      </Link>
                    </Permission>
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={data || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                filterValue={filterValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
              <AddRegion
                toggle={() => setIsAddModalOpen((state) => !state)}
                isModalOpen={isAddModalOpen}
                fetchData={fetchData}
              />
              <UpdateRegion
                toggle={() => setIsUpdateModalOpen((state) => !state)}
                isModalOpen={isUpdateModalOpen}
                selectedRecord={selectedRecord}
                fetchData={fetchUpdatedData}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Regions;
