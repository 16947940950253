import React from "react";
import ImportokWizard from "@importok/react";
import style from "./import-styles";
import { getAgencies } from "common/services/agencies.service";
import {
  getPerformanceRatingTypes,
  importPerformanceRating,
} from "common/services/performance-rating.service";
import { getContractors } from "common/services/contractors.service";
import { normalize } from "common/lib/normalize";
import * as moment from "moment";

const PerformanceRatingsImport: React.FC<{}> = () => {
  const fields = {
    contractorName: {
      label: "Contractor",
      validators: "in:contractors|required",
      transformers: "trim|as:contractors",
      provider: "contractors",
    },
    rating: {
      label: "Performance Rating Type",
      validators: "required|in:performanceRatingTypes",
      transformers: "trim|as:performanceRatingTypes",
      provider: "performanceRatingTypes",
    },
    reason: {
      label: "Reason",
      validators: "required",
      transformers: "trim",
    },
    ratedBy: {
      label: "Rated By",
      validators: "required|in:agencies",
      transformers: "trim|as:agencies",
      provider: "agencies",
    },
    from: {
      label: "From",
      validators: "date:dd/mm/yyyy|required",
      transformers: "trim",
    },
    to: {
      label: "To",
      validators: "date:dd/mm/yyyy",
      transformers: "trim",
    },
  };

  const transformers = {};

  const validators = {};

  const providers = {
    contractors: {
      get: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $eqi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.contractorName,
        };
      },
      find: async function (query: any) {
        const response = await getContractors({
          filters: {
            contractorName: {
              $containsi: query,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.id,
          label: item.contractorName,
        }));
      },
    },
    agencies: {
      get: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getAgencies({
          filters: {
            name: {
              $containsi: query,
            },
            category: {
              $null: false,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
      },
    },
    performanceRatingTypes: {
      get: async function (query: any) {
        const response = await getPerformanceRatingTypes({
          filters: {
            name: query,
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = normalize(response.data);
        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return {
          value: data?.[0]?.id,
          label: data?.[0]?.name,
        };
      },
      find: async function (query: any) {
        const response = await getPerformanceRatingTypes({
          filters: {
            name: {
              $containsi: query,
            },
            category: {
              $null: false,
            },
          },
        });

        if (!response.data) {
          throw new Error(`Response status ${response.status}`);
        }

        const data: any = await normalize(response.data);

        if (data.length === 0) {
          throw new Error(`Response status ${response.status}`);
        }

        return data.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
      },
    },
  };

  const saveRecord = async function (record: any, meta: any) {
    return await importPerformanceRating({
      data: {
        contractor: record?.properties?.contractorName,
        performanceRatingType: record?.properties.rating,
        ratedBy: record?.properties.ratedBy,
        from:
          (record?.properties.from &&
            moment(record?.properties.from, "DD/MM/YYYY").toDate()) ||
          null,
        to:
          (record?.properties.to &&
            moment(record?.properties.to, "DD/MM/YYYY").toDate()) ||
          null,
        description: record?.properties.reason,
      },
    });
  };

  return (
    <ImportokWizard
      title="Import Performance Ratings"
      fields={fields}
      transformers={transformers}
      validators={validators}
      providers={providers}
      onRecordReady={saveRecord}
      // @ts-ignore
      style={style}
    />
  );
};

export default PerformanceRatingsImport;
