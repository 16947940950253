import React from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  CardBody,
  Card,
} from "reactstrap";

import SelectOrganizationType from "./SelectOrganizationType";
import AliasNamesInput from "./AliasNamesInput";
import ContactPersonsInput from "./ContactPersonsInput";
import ContractorRegistration from "./ContractorRegistration";
import PhoneNumberInput from "./PhoneNumberInput";
import EmailInput from "./EmailInput";
import PhysicalAddressInput from "./PhysicalAddressInput";
import PostalAddressInput from "./PostalAddressInput";

const ContractorForm: React.FC<{
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading }) => {
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col md={12} className="mb-3">
          <Card>
            {/* <CardHeader>
              <h4 className="card-title mb-0">Contractor Information</h4>
            </CardHeader> */}
            <CardBody>
              <Row>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Contractor Name
                  </Label>
                  <Input
                    name="contractorName"
                    id="contractorName"
                    className="form-control"
                    placeholder="Enter contractor name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.contractorName || ""}
                    invalid={
                      validation.touched.contractorName &&
                      validation.errors.contractorName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.contractorName &&
                  validation.errors.contractorName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.contractorName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Acronym
                  </Label>
                  <Input
                    name="acronym"
                    id="acronym"
                    className="form-control"
                    placeholder="Enter acronym"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.acronym || ""}
                    invalid={
                      validation.touched.acronym && validation.errors.acronym
                        ? true
                        : false
                    }
                  />
                  {validation.touched.acronym && validation.errors.acronym ? (
                    <FormFeedback type="invalid">
                      {validation.errors.acronym}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-1">
                  <SelectOrganizationType
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
                <Col md={6} className="mb-1">
                  <Label htmlFor="id-field" className="form-label">
                    Business Owner
                  </Label>
                  <Input
                    name="businessOwner"
                    id="businessOwner"
                    className="form-control"
                    placeholder="Enter business owner"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.businessOwner || ""}
                    invalid={
                      validation.touched.businessOwner &&
                      validation.errors.businessOwner
                        ? true
                        : false
                    }
                  />
                  {validation.touched.businessOwner &&
                  validation.errors.businessOwner ? (
                    <FormFeedback type="invalid">
                      {validation.errors.businessOwner}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={12} className="">
                  <Label className="form-label">Previous or Alias Name</Label>
                </Col>
                <Col md={12} className="mb-3">
                  <AliasNamesInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={12}>
                  <Label className="form-label">Phone Number</Label>
                </Col>
                <Col md={12} className="">
                  <PhoneNumberInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={12} className="">
                  <Label className="form-label">Email Address</Label>
                </Col>
                <Col md={12} className="">
                  <EmailInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={12} className="">
                  <Label className="form-label">Contact Person</Label>
                </Col>
                <Col md={12} className="">
                  <ContactPersonsInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12} className="">
                      <Label htmlFor="id-field" className="form-label">
                        Business Website
                      </Label>
                      <Input
                        name="website"
                        id="website"
                        className="form-control"
                        placeholder="Enter website"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.website || ""}
                        invalid={
                          validation.touched.website &&
                          validation.errors.website
                            ? true
                            : false
                        }
                      />
                      {validation.touched.website &&
                      validation.errors.website ? (
                        <FormFeedback type="invalid">
                          {validation.errors.website}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            {/* <CardHeader>
              <h4 className="card-title mb-0"> Physical Address</h4>
            </CardHeader> */}
            <CardBody>
              <Row>
                <Col md={12} className="">
                  <Label className="form-label">Physical Address</Label>
                </Col>
                <Col md={12} className="">
                  <PhysicalAddressInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col md={12} className="">
                  <Label className="form-label">Postal Address</Label>
                </Col>
                <Col md={12} className="">
                  <PostalAddressInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            {/* <CardHeader>
              <h4 className="card-title mb-0">Registration Details</h4>
            </CardHeader> */}
            <CardBody>
              <Row>
                <Col md={12} className="">
                  <Label className="form-label">Registration Details</Label>
                </Col>
                <Col md={12} className="">
                  <ContractorRegistration validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="mb-1">
                <div className="hstack gap-2 justify-content-between">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {}}
                  >
                    Back
                  </button>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        color="light"
                        size="sm"
                        style={{ marginRight: "8px", marginBottom: "-1px" }}
                      ></Spinner>
                    )}
                    {isUpdate ? "Update Contractor" : " Add Contractor"}
                  </button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractorForm;
