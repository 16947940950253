import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ICON_CONTRACTORS,
  ICON_PEOPLE,
  ICON_USERS,
  ICON_ASSESSMENTS,
  ICON_CONTRACTS,
} from "../common/constants";
import { useProfile } from "Components/Hooks/UserHooks";

const Navdata = () => {
  const history = useNavigate();

  const { userProfile } = useProfile();
  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false);
  const [isDueDiligenceSearch, setIsDueDiligenceSearch] =
    useState<boolean>(false);
  const [isContractors, setIsContractors] = useState<boolean>(false);
  const [isContracts, setIsContracts] = useState<boolean>(false);
  const [isPersons, setIsPeople] = useState<boolean>(false);
  const [isAssessments, setIsAssessments] = useState<boolean>(false);

  const [isSanctionsList, setIsSanctionsList] = useState<boolean>(false);
  const [isReports, setIsReports] = useState<boolean>(false);
  const [isUsers, setIsUsers] = useState<boolean>(false);
  const [isAgencyDashboard, setIsAgencyDashboard] = useState<boolean>(false);
  const [isDueDiligenceRequests, setIsDueDiligenceRequests] =
    useState<boolean>(false);
  const [isAgencyData, setIsAgencyData] = useState<boolean>(false);
  const [isAgencyUsers, setIsAgencyUsers] = useState<boolean>(false);
  const [isRegions, setIsRegions] = useState<boolean>(false);
  const [isAgencies, setIsAgencies] = useState<boolean>(false);
  const [isDataTypes, setIsDataTypes] = useState<boolean>(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "DueDiligenceSearch") {
      setIsDueDiligenceSearch(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isUsers,
    isAgencyDashboard,
    isDueDiligenceRequests,
    isAgencyData,
    isContractors,
    isContracts,
    isPersons,
    isAssessments,
    isSanctionsList,
    isRegions,
    isDataTypes,
  ]);

  const menuItems: any = [
    {
      label: "General",
      isHeader: true,
      checks: [
        { resource: "contractor", action: "find" },
        { resource: "contract", action: "find", controller: "country" },
        { resource: "person", action: "find" },
        { resource: "risk-assessment", action: "find" },
        { resource: "capacity-assessment", action: "find" },
        { resource: "partner-verification-report", action: "find" },
        { resource: "us-sanctions-list", action: "find" },
        { resource: "eu-sanctions-list", action: "find" },
        { resource: "uk-sanctions-list", action: "find" },
        { resource: "un-designated-list", action: "find" },
        { resource: "contractor", action: "find" },
      ],
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      checks: [
        { resource: "contractor", action: "find" },
        { resource: "contract", action: "find", controller: "country" },
        { resource: "person", action: "find" },
        { resource: "risk-assessment", action: "find" },
        { resource: "capacity-assessment", action: "find" },
        { resource: "partner-verification-report", action: "find" },
        { resource: "us-sanctions-list", action: "find" },
        { resource: "eu-sanctions-list", action: "find" },
        { resource: "uk-sanctions-list", action: "find" },
        { resource: "un-designated-list", action: "find" },
        { resource: "contractor", action: "find" },
      ],
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "due-diligence-search",
      label: "Due Diligence Search",
      icon: "ri-search-eye-line",
      link: "/due-diligence-search",
      checks: [{ resource: "contractor", action: "find" }],
      click: function (e: any) {
        e.preventDefault();
        setIsDueDiligenceSearch(!isDueDiligenceSearch);
        setIscurrentState("DueDiligenceSearch");
        updateIconSidebar(e);
      },
      stateVariables: isDueDiligenceSearch,
    },

    {
      id: "contractors",
      label: "Contractors",
      icon: ICON_CONTRACTORS,
      link: "/contractors",
      checks: [{ resource: "contractor", action: "find" }],
      click: function (e: any) {
        e.preventDefault();
        setIsContractors(!isContractors);
        setIscurrentState("Contractors");
        updateIconSidebar(e);
      },
      stateVariables: isContractors,
    },
    {
      id: "contracts",
      label: "Contracts",
      icon: ICON_CONTRACTS,
      link: "/contracts",
      checks: [{ resource: "contract", action: "find" }],
      click: function (e: any) {
        e.preventDefault();
        setIsContracts(!isContracts);
        setIscurrentState("Contracts");
        updateIconSidebar(e);
      },
      stateVariables: isContracts,
    },
    {
      id: "ltas",
      label: "LTAs",
      icon: "ri-file-list-3-line",
      link: "/ltas",
      checks: [{ resource: "contract", action: "find" }],
      click: function (e: any) {
        e.preventDefault();
        setIsContracts(!isContracts);
        setIscurrentState("Contracts");
        updateIconSidebar(e);
      },
      stateVariables: isContracts,
    },
    {
      id: "persons",
      label: "Persons",
      icon: ICON_PEOPLE,
      link: "/persons",
      stateVariables: isPersons,
      checks: [{ resource: "person", action: "find" }],
      click: function (e: any) {
        e.preventDefault();
        setIsPeople(!isPersons);
        setIscurrentState("Persons");
        updateIconSidebar(e);
      },
    },

    {
      id: "assessments",
      label: "Assessments",
      icon: ICON_ASSESSMENTS,
      link: "/#",
      checks: [
        { resource: "risk-assessment", action: "find" },
        { resource: "capacity-assessment", action: "find" },
        { resource: "partner-verification-report", action: "find" },
      ],
      click: function (e: any) {
        e.preventDefault();
        setIsAssessments(!isAssessments);
        setIscurrentState("Assessments");
        updateIconSidebar(e);
      },
      stateVariables: isAssessments,
      subItems: [
        {
          id: "risk-assessments",
          label: "Risk Assessments",
          link: "/assessments/risk-assessments",
          parentId: "assessments",
          checks: [{ resource: "risk-assessment", action: "find" }],
        },

        {
          id: "capacity-assessments",
          label: "Capacity Assessments",
          link: "/assessments/capacity-assessments",
          parentId: "assessments",
          checks: [{ resource: "capacity-assessment", action: "find" }],
        },
        {
          id: "media",
          label: "Partner Verification Reports",
          link: "/assessments/pvrs",
          parentId: "baseUi",
          checks: [{ resource: "partner-verification-report", action: "find" }],
        },
      ],
    },
    {
      id: "sanctions-list",
      label: "Sanctions List",
      icon: "ri-shield-star-line",
      link: "/#",
      checks: [
        { resource: "us-sanctions-list", action: "find" },
        { resource: "eu-sanctions-list", action: "find" },
        { resource: "uk-sanctions-list", action: "find" },
        { resource: "un-designated-list", action: "find" },
        { resource: "contractor", action: "find" },
      ],
      click: function (e: any) {
        e.preventDefault();
        setIsSanctionsList(!isSanctionsList);
        setIscurrentState("SanctionsList");
        updateIconSidebar(e);
      },
      stateVariables: isSanctionsList,
      subItems: [
        {
          id: "us-sanctions-list",
          label: "US Sanctions List",
          link: "/sanctions-list/us",
          parentId: "sanctions-list",
          checks: [{ resource: "us-sanctions-list", action: "find" }],
        },
        {
          id: "eu-sanctions-list",
          label: "EU Sanction List",
          link: "/sanctions-list/eu",
          parentId: "sanctions-list",
          checks: [{ resource: "eu-sanctions-list", action: "find" }],
        },
        {
          id: "uk-sanctions-list",
          label: "UK Sanction List",
          link: "/sanctions-list/uk",
          parentId: "sanctions-list",
          checks: [{ resource: "uk-sanctions-list", action: "find" }],
        },
        {
          id: "ungm-sanctions-list",
          label: "UNGM Debarred List",
          link: "/sanctions-list/ungm",
          parentId: "sanctions-list",
          checks: [{ resource: "un-designated-list", action: "find" }],
        },
        {
          id: "wb-sanctions-list",
          label: "World Bank Banned List",
          link: "/sanctions-list/world-bank",
          parentId: "sanctions-list",
          checks: [{ resource: "contractor", action: "find" }],
        },
      ],
    },
    {
      id: "reports",
      label: "Reports",
      icon: "ri-file-word-2-line",
      link: "/#",
      checks: [{ resource: "reports", action: "find" }],
      click: function (e: any) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
      stateVariables: isReports,
      subItems: [
        {
          id: "contracts-report",
          label: "Contracts Report",
          link: "/reports/contracts-report",
          parentId: "reports",
          checks: [{ resource: "contract-reports", action: "find" }],
        },
        {
          id: "contractors-report",
          label: "Contractors Report",
          link: "/reports/contractors-reports",
          parentId: "reports",
          checks: [{ resource: "contractors-reports", action: "find" }],
        },
        {
          id: "allegations-report",
          label: "Allegations Report",
          link: "/reports/allegations-report",
          parentId: "reports",
          checks: [{ resource: "allegation-reports", action: "find" }],
        },
      ],
    },
    // {
    //   id: "users",
    //   label: "Users",
    //   icon: ICON_USERS,
    //   link: "/country-users",
    //   checks: [{ resource: "us-sanctions-list", action: "find" }],
    //   click: function (e: any) {
    //     e.preventDefault();
    //     setIsUsers(!isUsers);
    //     setIscurrentState("Users");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isUsers,
    // },

    {
      label: `${userProfile?.agency?.name || ""} ${
        userProfile?.country?.name || ""
      }`,
      isHeader: true,
      checks: [{ resource: "contract", action: "find", controller: "agency" }],
    },
    {
      id: "agency-dashboard",
      label: `${userProfile?.agency?.name || ""} Dashboard`,
      icon: "ri-pie-chart-line",
      link: "/agency/agency-dashboard/agency",
      checks: [{ resource: "contract", action: "find", controller: "agency" }],
      click: function (e: any) {
        e.preventDefault();
        setIsAgencyDashboard(!isAgencyDashboard);
        setIscurrentState("AgencyDashboard");
        updateIconSidebar(e);
      },
      stateVariables: isAgencyDashboard,
    },
    {
      id: "due-diligence-requests",
      label: "Due Diligence Requests",
      icon: "ri-bar-chart-horizontal-line",
      link: "/agency/due-diligence-requests",
      checks: [{ resource: "dd", action: "find" }],
      click: function (e: any) {
        e.preventDefault();
        setIsDueDiligenceRequests(!isDueDiligenceRequests);
        setIscurrentState("AgencyDueDiligenceRequests");
        updateIconSidebar(e);
      },
      stateVariables: isDueDiligenceRequests,
    },
    {
      id: "agency-data",
      label: `${userProfile?.agency?.name || ""} Data`,
      icon: "ri-database-2-line",
      link: "/#",
      checks: [{ resource: "contract", action: "find", controller: "agency" }],
      click: function (e: any) {
        e.preventDefault();
        setIsAgencyData(!isAgencyData);
        setIscurrentState("AgencyData");
        updateIconSidebar(e);
      },
      stateVariables: isAgencyData,
      subItems: [
        {
          id: "agency-data-contractors",
          label: "Contractors",
          link: "/agency-data/contractors",
          parentId: "agency-data",
          checks: [
            { resource: "contract", action: "find", controller: "agency" },
          ],
        },
        {
          id: "agency-data-contracts",
          label: "Contracts",
          link: "/agency-data/contracts",
          parentId: "agency-data",
          checks: [
            { resource: "contract", action: "find", controller: "agency" },
          ],
        },
        {
          id: "agency-data-assessments",
          label: "Assessments",
          link: "/agency-data/assessments",
          parentId: "agency-data",
          checks: [{ resource: "dd", action: "find" }],
        },
        {
          id: "agency-data-persons",
          label: "Persons",
          link: "/agency-data/persons",
          parentId: "agency-data",
          checks: [{ resource: "dd", action: "find" }],
        },
      ],
    },
    {
      id: "users",
      label: "Users",
      icon: "ri-group-line",
      link: "/agency/users",
      checks: [
        { resource: "user-management", action: "find", controller: "agency" },
      ],
      click: function (e: any) {
        e.preventDefault();
        setIsAgencyUsers(!isAgencyUsers);
        setIscurrentState("AgencyUsers");
        updateIconSidebar(e);
      },
      stateVariables: isUsers,
    },

    {
      label: "Site Administration",
      isHeader: true,
      checks: [
        { resource: "country", action: "create" },
        { resource: "agency", action: "create" },
        { resource: "region", action: "create" },
      ],
    },
    {
      id: "agencies",
      label: "Agencies",
      icon: "ri-earth-line",
      link: "/agencies",
      checks: [{ resource: "agency", action: "create" }],

      click: function (e: any) {
        e.preventDefault();
        setIsAgencies(!isAgencies);
        setIscurrentState("Agencies");
        updateIconSidebar(e);
      },
      stateVariables: isAgencies,
    },
    {
      id: "maps-regions",
      label: "Maps & Regions",
      icon: "ri-map-pin-line",
      link: "/#",
      checks: [{ resource: "country", action: "create" }],

      click: function (e: any) {
        e.preventDefault();
        setIsRegions(!isRegions);
        setIscurrentState("Regions");
        updateIconSidebar(e);
      },
      stateVariables: isRegions,
      subItems: [
        {
          id: "regions-countries",
          label: "Countries",
          link: "/countries",
          parentId: "maps-regions",
          checks: [{ resource: "country", action: "create" }],
        },
        {
          id: "regions",
          label: "Regions",
          link: "/regions",
          parentId: "maps-regions",
          checks: [{ resource: "region", action: "create" }],
        },
      ],
    },
    {
      id: "data-types",
      label: "Data Types",
      icon: "ri-database-2-line",
      link: "/#",
      checks: [{ resource: "country", action: "create" }],
      click: function (e: any) {
        e.preventDefault();
        setIsDataTypes(!isDataTypes);
        setIscurrentState("DataTypes");
        updateIconSidebar(e);
      },
      stateVariables: isDataTypes,
      subItems: [
        {
          id: "organization-types",
          label: "Organization Types",
          link: "/data-types/organization-types",
          parentId: "data-types",
          checks: [{ resource: "organization-type", action: "create" }],
        },
        {
          id: "contract-types",
          label: "Contract Types",
          link: "/data-types/contract-types",
          parentId: "data-types",
          checks: [{ resource: "contract-type", action: "create" }],
        },
        {
          id: "contract-ratings",
          label: "Contract Ratings",
          link: "/data-types/contract-ratings",
          parentId: "data-types",
          checks: [{ resource: "country", action: "create" }],
        },
        {
          id: "risk-assessment-ratings",
          label: "Risk Assessment Ratings",
          link: "/data-types/risk-assessment-ratings",
          parentId: "data-types",
          checks: [{ resource: "country", action: "create" }],
        },
        {
          id: "capacity-assessment-types",
          label: "Capacity Assessment Types",
          link: "/data-types/capacity-assessment-types",
          parentId: "data-types",
          checks: [{ resource: "country", action: "create" }],
        },
        {
          id: "capacity-assessment-ratings",
          label: "Capacity Assessment Ratings",
          link: "/data-types/capacity-assessment-ratings",
          parentId: "data-types",
          checks: [{ resource: "country", action: "create" }],
        },
      ],
    },
    {
      id: "users",
      label: "Users",
      icon: ICON_USERS,
      link: "/#",
      checks: [{ resource: "user-management", action: "find" }],

      click: function (e: any) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("Users");
        updateIconSidebar(e);
      },
      stateVariables: isUsers,
      subItems: [
        {
          id: "users-users",
          label: "All Users",
          link: "/users",
          parentId: "maps-regions",
          checks: [{ resource: "user-management", action: "find" }],
        },
      ],
    },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
