import React from "react";
import ImportokWizard from "@importok/react";
import { addContract } from "common/services/contracts.service";

const PersonsImport: React.FC<{}> = ({}) => {
  const fields = {
    title: {
      label: "Title",
      validators: "",
      transformers: "capitalize|trim",
    },
    firstName: {
      label: "First Name",
      validators: "required",
      transformers: "capitalize|trim",
    },
    middleName: {
      label: "Middle Name",
      validators: "",
      transformers: "trim",
    },
    surname: {
      label: "Surname",
      validators: "",
      transformers: "trim",
    },
    alias: {
      label: "Alias",
      validators: "",
      transformers: "trim",
    },
    gender: {
      label: "Gender",
      validators: "",
      transformers: "trim",
    },
    dateOfBirth: {
      label: "Date of Birth",
      validators: "",
      transformers: "trim",
    },
  };

  const transformers = {};

  const validators = {};

  const providers = {};

  const saveRecord = async function (record: any, meta: any) {
    return await addContract({
      data: {
        contractTitle: record?.properties?.contractTitle,
      },
    });
  };

  return (
    <ImportokWizard
      title="Import Persons"
      fields={fields}
      transformers={transformers}
      validators={validators}
      providers={providers}
      onRecordReady={saveRecord}
    />
  );
};

export default PersonsImport;
