import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const TermsAndConditionsModal: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
}> = ({ toggle, isModalOpen }) => {
  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
      scrollable
    >
      <ModalHeader className=" p-3" toggle={toggle}>
        CIMS Terms and Conditions
      </ModalHeader>

      <ModalBody>
        <p>
          I am acknowledging my access to the classified information in CIMS is
          strictly in accordance with my agency's requirements or the United
          Nations Secretary Generals Bulletin ST/SGB/2007/6 for Secretariat
          Agencies on handling classified information. I will not disclose any
          classified UN information to anybody without the requisite clearance
          from the Risk Management Unit. I will use the classified Information
          solely for the purpose for which it was supplied.
        </p>
        <p className="fw-bold">I FURTHER ACKNOWLEDGE THAT:</p>

        <ol>
          <li>
            "Classified Information" means:
            <ol type="a">
              <li>
                Documents, data, or information created by the United Nations,
                received from or sent to third parties, under an expectation of
                confidentiality;
              </li>
              <li>
                Documents, data, or information whose disclosure is likely to
                endanger the safety or security of any individual, violate his
                or her rights including privacy;
              </li>
              <li>
                Documents, data, or information whose disclosure is likely to
                endanger the security of Member States or prejudice the security
                or proper conduct of any operation or activity of the United
                Nations, including the activities of the Office of the Resident
                and Humanitarian Coordinator for Somalia, the operations of the
                United Nations Country Team – Somalia, and any United Nations
                peacekeeping operations;
              </li>
              <li>
                Documents, data, or information covered by legal privilege or
                related to internal UN investigations;
              </li>
              <li>
                Internal inter-office or intra-office documents, including draft
                documents, if disclosure would undermine the United Nations free
                and independent decision-making process;
              </li>
              <li>
                Documents, data, or information containing commercial
                information, if disclosure would harm the financial interests,
                reputational risk, or operational activities of the United
                Nations Country Team - Somalia;
              </li>
              <li>
                Documents, data, or information (including verbal and/or
                information derived from third parties) received in connection
                with or as a result of his or her employment, contributions,
                support or engagement with the United Nations Country Team for
                Somalia Contractor Information Management System (CIMS);
              </li>
              <li>
                Other kinds of information, which because of their content or
                the circumstances of their creation or communication must be
                deemed confidential.
              </li>
            </ol>
          </li>
          <li>
            I also acknowledge and understand that:
            <ol type="a">
              <li>
                I will receive and handle all Classified Information in
                accordance with the rules on Information Sensitivity,
                Classification and Handling and will hold/store all Classified
                Information that comes to my attention or knowledge whether
                incidentally or otherwise in accordance with these provisions
                for so long as it remains Classified Information; and
              </li>
              <li>
                I will use the Classified Information that I receive or that
                comes to my attention or knowledge whether incidentally or
                otherwise solely for the purpose that it was supplied to the
                United Nations.
              </li>
              <li>
                I will not disclose any classified UN information to anybody
                without the requisite clearance from Risk Management Unit.
              </li>
            </ol>
          </li>
        </ol>
      </ModalBody>
    </Modal>
  );
};

export default TermsAndConditionsModal;
