import React from "react";
import {
  Form,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import SelectContractor from "../../Contracts/SelectContractor";
import Flatpickr from "react-flatpickr";
import SelectAgency from "pages/Users/SelectAgency";
import SelectReportFile from "../RiskAssessments/SelectReportFile";
import SelectCapacityAssessmentType from "./SelectCapacityAssessmentType";
import SelectCapacityAssessmentRating from "./SelectCapacityAssessmentRating";
import SelectConductedBy from "./SelectConductedBy";
import SelectSensitivity from "../RiskAssessments/SelectSensitivity";

const CapacityAssessmentForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, toggle, isUpdate, loading, setLoading }) => {
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody>
        <input type="hidden" id="id-field" />

        <div className="mb-3">
          <Label htmlFor="id-field" className="form-label">
            Report Title
          </Label>
          <Input
            name="reportTitle"
            id="reportTitle"
            className="form-control"
            placeholder="Enter report title"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.reportTitle || ""}
            invalid={
              validation.touched.reportTitle && validation.errors.reportTitle
                ? true
                : false
            }
          />
          {validation.touched.reportTitle && validation.errors.reportTitle ? (
            <FormFeedback type="invalid">
              {validation.errors.reportTitle}
            </FormFeedback>
          ) : null}
        </div>

        <Row>
          <Col md={6} className="">
            <SelectAgency validation={validation} />
          </Col>
          <Col md={6} className="">
            <SelectContractor validation={validation} />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <SelectCapacityAssessmentType
              validation={validation}
              setLoading={setLoading}
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectCapacityAssessmentRating validation={validation} />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <Label htmlFor="startDate-field" className="form-label">
              Report Date
            </Label>
            {/* @ts-ignore */}
            <Flatpickr
              name="reportDate"
              id="reportDate-field"
              className="form-control"
              placeholder="Select report date"
              options={{
                enableTime: false,
                altInput: false,
                altFormat: "d M, Y",
                dateFormat: "d M, Y",
              }}
              onChange={(e) => {
                validation.setFieldValue("reportDate", e[0]);
              }}
              value={validation.values.reportDate || ""}
            />
            {validation.touched.reportDate && validation.errors.reportDate ? (
              <FormFeedback type="invalid">
                {validation.errors.reportDate}
              </FormFeedback>
            ) : null}
          </Col>
          <Col md={6} className="mb-3">
            <SelectConductedBy validation={validation} />
          </Col>
          {/* <Col md={4} className="">
            <SelectSensitivity validation={validation} />
          </Col> */}
        </Row>

        <Row>
          <Col md={12} className="mb-3">
            <Label htmlFor="id-field" className="form-label">
              Summary of findings
            </Label>
            <Input
              name="summaryOfFindings"
              id="summaryOfFindings"
              className="form-control"
              placeholder="Enter summary of findings"
              type="textarea"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.summaryOfFindings || ""}
              invalid={
                validation.touched.summaryOfFindings &&
                validation.errors.summaryOfFindings
                  ? true
                  : false
              }
            />
            {validation.touched.summaryOfFindings &&
            validation.errors.summaryOfFindings ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row>
          <SelectReportFile validation={validation} />
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {isUpdate
              ? "Update Capacity Assessment"
              : " Add Capacity Assessment"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default CapacityAssessmentForm;
