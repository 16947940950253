import {
  useEffect,
  useState,
  useContext,
  createContext,
  ReactNode,
} from "react";
import { getLoggedinUser } from "../../helpers/api_helper";
import { useDispatch } from "react-redux";
import { fetchLoggedInUser } from "slices/thunks";
import { useLocation } from "react-router-dom";

// Define the shape of the user profile
export interface UserProfile {
  token: string;
  userId: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  role: any;
  agency: any;
  country: any;
  permissions: any;
  createdAt: string;
  updatedAt: string;
  title: string;
}

// Define the shape of the user context
export interface UserContextType {
  userProfile: UserProfile | null;
  loading: boolean;
  token: string;
}

const UserContext = createContext<UserContextType>({
  userProfile: null,
  loading: false,
  token: "",
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const userProfileSession = getLoggedinUser();
  var token = userProfileSession && userProfileSession["token"];
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState<UserProfile>(
    userProfileSession ? userProfileSession : null
  );
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(fetchLoggedInUser()).then((response: any) => {
      const userProfileSession = getLoggedinUser();
      var token = userProfileSession && userProfileSession["token"];
      setUserProfile(userProfileSession ? userProfileSession : null);
      setLoading(token ? false : true);
    });
  }, [dispatch]);

  return (
    <UserContext.Provider value={{ userProfile, loading, token }}>
      {children}
    </UserContext.Provider>
  );
};

export const useProfile = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useProfile must be used within a UserProvider");
  }
  return context;
};

export const useLocalProfile = () => {
  const userProfileSession = getLoggedinUser();
  var token = userProfileSession && userProfileSession["token"];
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    var token = userProfileSession && userProfileSession["token"];
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);
  }, []);

  return { userProfile, loading, token };
};
