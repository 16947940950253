import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateContractor } from "common/services/contractors.service";
import { toast } from "react-toastify";
import ContactDetailsForm from "./ContactDetailsForm";

const UpdateContactDetails: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;

  contractor: any;
}> = ({ toggle, isModalOpen, contractor, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const contactPersons = contractor?.contactPersons || [];
  const physicalAddress = contractor?.physicalAddress || [];
  const postalAddress = contractor?.postalAddress || [];
  const emailAddress = contractor?.emailAddress || [];
  const phoneNumber = contractor?.phoneNumber || [];

  console.log("contractor", contractor);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      phoneNumber: (phoneNumber.length > 0 &&
        phoneNumber.map((item: any) => item.name)) || [""],
      emailAddress: (emailAddress.length > 0 &&
        emailAddress.map((item: any) => {
          return item.name;
        })) || [""],
      website: contractor?.website || "",
      postalAddress: (postalAddress.length > 0 &&
        postalAddress.map((item: any) => {
          return {
            address: item.address,
            city: item.city,
            country: item.country,
          };
        })) || [
        {
          address: "",
          city: "",
          country: "",
        },
      ],

      physicalAddress: (physicalAddress.length > 0 &&
        physicalAddress.map((item: any) => {
          return {
            address: item.address,
            city: item.city,
            country: item.country,
          };
        })) || [
        {
          address: "",
          city: "",
          country: "",
        },
      ],
      contactPersons: (contactPersons.length > 0 &&
        contactPersons.map((item: any) => ({
          name: item.name,
        }))) || [
        {
          name: "",
        },
      ],
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.array().of(Yup.string()),
      emailAddress: Yup.array().of(Yup.string()),
      website: Yup.string().nullable(),
      physicalAddress: Yup.array().of(
        Yup.object({
          city: Yup.string().nullable(),
          country: Yup.string().nullable(),
          address: Yup.string().nullable(),
        })
      ),
      postalAddress: Yup.array().of(
        Yup.object({
          city: Yup.string().nullable(),
          country: Yup.string().nullable(),
          address: Yup.string().nullable(),
        })
      ),
      contactPersons: Yup.array().of(
        Yup.object({
          name: Yup.string().nullable(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      // Remove empty fields from physicalAddress, postalAddress, contractorRegistration, contact persons
      values["physicalAddress"] = values["physicalAddress"].filter(
        (el: any) => el.city !== "" || el.country !== "" || el.address !== ""
      );
      values["postalAddress"] = values["postalAddress"].filter(
        (el: any) => el.city !== "" || el.country !== "" || el.address !== ""
      );

      values["contactPersons"] = values["contactPersons"].filter(
        (el: any) => el.name !== ""
      );
      const data = {
        id: contractor.id,
        data: {
          phoneNumber:
            Array.isArray(values["phoneNumber"]) &&
            values["phoneNumber"].length > 0
              ? values["phoneNumber"]
                  .map((el) => {
                    if (el !== "") {
                      return {
                        name: el,
                      };
                    }
                    return null;
                  })
                  .filter((el) => el !== null)
              : [],
          emailAddress:
            Array.isArray(values["emailAddress"]) &&
            values["emailAddress"].length > 0
              ? values["emailAddress"]
                  .map((el) => {
                    if (el !== "") {
                      return {
                        name: el,
                      };
                    }
                    return null;
                  })
                  .filter((el) => el !== null)
              : [],
          website: values["website"],
          physicalAddress: values["physicalAddress"],
          postalAddress: values["postalAddress"],
          contactPersons: values["contactPersons"],
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updateContractor(data);
          validation.resetForm();
          toggle();
          toast.success("Contact Details Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating contact details", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  console.log("Physical Address", validation.values.physicalAddress);

  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contact Details
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.contractorRegistration?.length || 0}
        </small>
      </ModalHeader>

      <ContactDetailsForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateContactDetails;
