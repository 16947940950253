import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { getAgencies } from "common/services/agencies.service";
import { normalize } from "common/lib/normalize";

const SelectAgency = ({
  validation,
  setLoading,
  label,
  index,
}: {
  validation: any;
  setLoading: any;
  label?: any;
  index: number;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.performanceRatings?.[index]?.agency &&
        validation.errors.performanceRatings?.[index]?.agency
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.performanceRatings?.[index]?.agency &&
          validation.errors.performanceRatings?.[index]?.agency
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = React.useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getAgencies({
          pagination: {
            page: 1,
            pageSize: 10000,
          },
          filters: {
            category: {
              $null: false,
            },
          },
        });
        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label
        htmlFor={`performanceRatings[${index}].agency`}
        className="form-label"
      >
        {label ? label : "Agency"}
      </Label>
      <Select
        name={`performanceRatings[${index}].agency`}
        id={`performanceRatings[${index}].agency`}
        value={
          validation.values.performanceRatings[index]?.agency?.value
            ? validation.values.performanceRatings[index]?.agency
            : null
        }
        placeholder="Select agency"
        onChange={(value: any) => {
          validation.setFieldValue(
            `performanceRatings[${index}].agency`,
            value
          );
        }}
        options={options}
        onBlur={() =>
          validation.setFieldTouched(
            `performanceRatings[${index}].agency`,
            true
          )
        }
        className={
          validation.touched.performanceRatings?.[index]?.agency &&
          validation.errors.performanceRatings?.[index]?.agency
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>
        {validation.errors.performanceRatings?.[index]?.agency?.value}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectAgency;
