import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getCountryContracts as getCountryContractsApi,
  addCountryContract as addCountryContractApi,
  updateCountryContract as updateCountryContractApi,
  deleteCountryContract as deleteCountryContractApi,
  getCountryContractsSummary as getCountryContractsSummaryApi,
  getAgencyContracts as getAgencyContractsApi,
  addAgencyContract as addAgencyContractApi,
  updateAgencyContract as updateAgencyContractApi,
  deleteAgencyContract as deleteAgencyContractApi,
  getContractRatings as getContractRatingsApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";
import { LEVEL_AGENCY, LEVEL_COUNTRY } from "common/constants";

import { getContract as getContractApi } from "common/services/contracts.service";

export const getContracts = createAsyncThunk(
  "contract/getContracts",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = getCountryContractsApi(payload?.params);
      }
      if (payload?.level === LEVEL_AGENCY) {
        response = getAgencyContractsApi(payload?.params);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getContract = createAsyncThunk(
  "contract/getContract",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getContractApi(payload?.id, {});

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addContract = createAsyncThunk(
  "contract/addContract",
  async (payload: any) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = addCountryContractApi(payload?.params);
      }
      if (payload?.level === LEVEL_AGENCY) {
        response = addAgencyContractApi(payload?.params);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateContract = createAsyncThunk(
  "contract/updateContract",
  async (payload: any) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = updateCountryContractApi(payload?.params);
      }
      if (payload?.level === LEVEL_AGENCY) {
        response = updateAgencyContractApi(payload?.params);
      }
      const data = response;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteContract = createAsyncThunk(
  "contract/deleteContract",
  async (payload: any) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = deleteCountryContractApi(payload?.params);
      }
      if (payload?.level === LEVEL_AGENCY) {
        response = deleteAgencyContractApi(payload?.params);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getContractRatings = createAsyncThunk(
  "contract/getContractRatings",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getContractRatingsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getContractsSummary = createAsyncThunk(
  "contract/getContractsSummary",
  async (payload: any, { dispatch }) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = getCountryContractsSummaryApi(payload?.params);
      }
      // if (payload?.level === LEVEL_AGENCY) {
      //   response = getAgencyContractsApi(payload?.params);
      // }
      return response;
    } catch (error) {
      return error;
    }
  }
);
