import React, { useMemo } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";

const SelectLevel = ({ validation }: { validation: any }) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.level && validation.errors.level ? "red" : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.level && validation.errors.level
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const options = useMemo(() => {
    return [
      {
        value: 0,
        label: "0",
      },
      {
        value: 1,
        label: "1",
      },
      {
        value: 2,
        label: "2",
      },
    ];
  }, []);

  return (
    <FormGroup>
      <Label htmlFor="level-field" className="form-label">
        Administration Level
      </Label>
      <Select
        name="level"
        id="level"
        value={
          validation.values.level.value || validation.values.level.value === 0
            ? validation.values.level
            : null
        }
        placeholder="Select level"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          validation.setFieldValue("level", value);
          validation.setFieldError("level", "");
        }}
        options={options}
        // @ts-ignore
        onBlur={() => validation.setFieldTouched("level", true)}
        className={
          validation.touched.level && validation.errors.level
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.level?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectLevel;
