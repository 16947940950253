import React, { useMemo, useEffect, useState } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { getContractTypes } from "common/services/contracts.service";
import { normalize } from "common/lib/normalize";

const SelectContractType = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: (loading: boolean) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.contractType && validation.errors.contractType
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.contractType && validation.errors.contractType
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getContractTypes({
          pagination: {
            page: 1,
            pageSize: 10000,
          },
        });

        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label htmlFor="contractType-field" className="form-label">
        Contract Type
      </Label>
      <Select
        name="contractType"
        id="contractType"
        value={
          validation.values.contractType.value
            ? validation.values.contractType
            : null
        }
        isSearchable={false}
        placeholder="Select contract type"
        onChange={(value: any) => {
          validation.setFieldValue("contractType", value);
          validation.setFieldError("contractType", "");
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("contractType", true)}
        className={
          validation.touched.contractType && validation.errors.contractType
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.contractType?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectContractType;
