import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { getCapacityAssessmentRatings } from "common/services/capacity-assessments.service";
import { normalize } from "common/lib/normalize";

const SelectCapacityAssessmentRating = ({
  validation,
}: {
  validation: any;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.capacityAssessmentRating &&
        validation.errors.capacityAssessmentRating
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.capacityAssessmentRating &&
          validation.errors.capacityAssessmentRating
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>([]);

  const assessmentType = validation.values?.capacityAssessmentType?.value;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: any = await getCapacityAssessmentRatings({
          filters: {
            capacityAssessmentType: assessmentType || 0,
          },
        });
        setData(normalize(response.data));
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, setLoading, assessmentType]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.name}`,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label htmlFor="capacityAssessmentRating-field" className="form-label">
        Overall Rating
      </Label>
      <Select
        isDisabled={!validation.values.capacityAssessmentType.value}
        name="capacityAssessmentRating"
        id="capacityAssessmentRating"
        value={
          validation.values?.capacityAssessmentRating?.value
            ? validation.values.capacityAssessmentRating
            : null
        }
        placeholder="Select capacity assessment rating"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          validation.setFieldValue("capacityAssessmentRating", value);
          validation.setFieldError("capacityAssessmentRating", "");
        }}
        options={options}
        onBlur={() =>
          validation.setFieldTouched("capacityAssessmentRating", true)
        }
        className={
          validation.touched.capacityAssessmentRating &&
          validation.errors.capacityAssessmentRating
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
        isLoading={loading}
      />
      <FormFeedback>
        {validation.errors.capacityAssessmentRating?.value}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectCapacityAssessmentRating;
