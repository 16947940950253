import React, { useEffect, useState, useMemo } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Card,
  Col,
  Input,
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../../Components/Common/DeleteModal";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { Rating, Published, Status } from "./CapacityAssessmentCol";
//Import data

//Import actions
import // getProducts as onGetProducts,
// deleteProducts,
"../../../slices/thunks";
import { isEmpty } from "lodash";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { createSelector } from "reselect";

const AgencyAssessments = (props: any) => {
  const dispatch: any = useDispatch();

  const selectecomproductData = createSelector(
    (state: any) => state.Ecommerce,
    (products) => products.products
  );

  const products = [
    {
      contractorName: "Association of Somalia Women Lawyers (ASWL)",
      assessmentType: "Risk Assessment",
      assessmentDate: "2021-09-21",
      riskLevel: "High",
      status: "Completed",
      organizationType: "NGO",
      agency: "OCHA",
    },
    {
      contractorName: "24/7 Securit Services Private Company",
      assessmentType: "Risk Assessment",
      assessmentDate: "2021-09-21",
      riskLevel: "Medium",
      status: "Completed",
      organizationType: "Private Company",
      agency: "WHO",
    },
    {
      contractorName: "Aaran Enterprises Company",
      assessmentType: "Risk Assessment",
      assessmentDate: "2021-09-21",
      riskLevel: "Low",
      status: "Completed",
      organizationType: "Private Company",
      agency: "UNHCR",
    },
  ];

  const [productList, setProductList] = useState<any>([]);
  const [product, setProduct] = useState<any>(null);

  useEffect(() => {
    if (products && !products.length) {
      // dispatch(onGetProducts());
    }
  }, [dispatch, products]);

  useEffect(() => {
    setProductList(products);
  }, [products]);

  useEffect(() => {
    if (!isEmpty(products)) setProductList(products);
  }, [products]);

  useEffect(() => {
    const sliderq = document.getElementById("product-price-range");
    sliderq?.setAttribute("data-slider-color", "success");
  }, []);

  //delete order
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState<boolean>(false);

  const onClickDelete = (product: any) => {
    setProduct(product);
    setDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (product) {
      // dispatch(deleteProducts(product.id));
      setDeleteModal(false);
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element") as HTMLElement;
    ele.forEach((element: any) => {
      // dispatch(deleteProducts(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
      del.style.display = "none";
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Contractor Name",
        accessorKey: "contractorName",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm">
                  <div className="avatar-title bg-light text-danger rounded fs-24">
                    <i className="ri-file-ppt-2-line"></i>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to="/apps-ecommerce-product-details"
                    className="text-body"
                  >
                    {" "}
                    {cell.getValue()}
                  </Link>
                </h5>
                <p className="text-muted mb-0">
                  Organization Type :{" "}
                  <span className="fw-medium">
                    {" "}
                    {cell.row.original.organizationType}
                  </span>
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        header: "Risk Level",
        accessorKey: "riskLevel",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <Rating {...cell} />;
        },
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <Status {...cell} />;
        },
      },
      {
        header: "UN Agency",
        accessorKey: "agency",
        enableColumnFilter: false,
      },
      {
        header: "Assessment Date",
        accessorKey: "assessmentDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <Published {...cell} />;
        },
      },
      {
        header: "Action",
        cell: (cell: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm"
                tag="button"
              >
                <i className="ri-more-fill" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="apps-ecommerce-product-details">
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                  Download
                </DropdownItem>

                <DropdownItem href="apps-ecommerce-add-product">
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                  Edit
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const productData = cell.row.original;
                    onClickDelete(productData);
                  }}
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  document.title = "WFP Capacity Assessments | CIMS";

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb
          title="Capacity / Micro Assessments"
          pageTitle="Assessments"
        />

        <Row>
          <Col xl={12} lg={12}>
            <div>
              <Card>
                <div className="card-header border-0">
                  <Row className="align-items-center g-3">
                    <Col md={3}>
                      <h5 className="card-title mb-0">
                        Capacity / Micro Assessments
                      </h5>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex gap-2"></div>
                    </div>
                  </Row>
                </div>
                <div className="card-body pt-0">
                  {productList && productList.length > 0 ? (
                    <TableContainer
                      loading={false}
                      columns={columns}
                      data={[]}
                      pagination={{}}
                      onPageChange={() => {}}
                      FilterSection={() => {}}
                      totalRows={0}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle table-borderless"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <i className="ri-search-line display-5 text-success"></i>
                      </div>

                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AgencyAssessments;
