import SelectOrganizationType from "pages/Contractors/SelectOrganizationType";
import SelectCurrentNationality from "pages/Persons/SelectCurrentNationality";
import SelectGender from "pages/Persons/SelectGender";
import SelectNationalityAtBirth from "pages/Persons/SelectNationalityAtBirth";
import SelectTitle from "pages/Persons/SelectTitle";
import React, { useEffect, useRef } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  ModalBody,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";

const PersonForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading, toggle }) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <SimpleBar
          ref={simpleBarRef}
          style={{ maxHeight: "80vh" }}
          className=" px-4 py-4"
          autoHide={false}
        >
          <Row>
            <Col md={12} className="mb-3">
              <Row>
                <Col md={6} className="mb-3">
                  <SelectTitle
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    First Name
                  </Label>
                  <Input
                    name="firstName"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter contractor name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                    invalid={
                      validation.touched.firstName &&
                      validation.errors.firstName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Middle Name
                  </Label>
                  <Input
                    name="middleName"
                    id="middleName"
                    className="form-control"
                    placeholder="Enter middle name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.middleName || ""}
                    invalid={
                      validation.touched.middleName &&
                      validation.errors.middleName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.middleName &&
                  validation.errors.middleName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.middleName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Surname
                  </Label>
                  <Input
                    name="surname"
                    id="surname"
                    className="form-control"
                    placeholder="Enter last name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.surname || ""}
                    invalid={
                      validation.touched.surname && validation.errors.surname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.surname && validation.errors.surname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.surname}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <SelectGender
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="dateOfBirth-field" className="form-label">
                    Date of Birth
                  </Label>
                  {/* @ts-ignore */}
                  <Flatpickr
                    name="dateOfBirth"
                    id="dateOfBirth-field"
                    className="form-control"
                    placeholder="Select start date"
                    options={{
                      enableTime: false,
                      altInput: false,
                      altFormat: "d M, Y",
                      dateFormat: "d M, Y",
                    }}
                    onChange={(e) => {
                      validation.setFieldValue("dateOfBirth", e[0]);
                    }}
                    value={validation.values.dateOfBirth || ""}
                  />
                  {validation.touched.dateOfBirth &&
                  validation.errors.dateOfBirth ? (
                    <FormFeedback type="invalid">
                      {validation.errors.dateOfBirth}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <SelectNationalityAtBirth
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectCurrentNationality
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </SimpleBar>
      </ModalBody>
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default PersonForm;
