import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "pages/Dashboard";

// //login
import Login from "pages/Authentication/Login";
import ForgetPasswordPage from "pages/Authentication/ForgetPassword";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";
import ResetPassword from "pages/Authentication/ResetPassword";

//Charts
// Landing Index
// // User Profile
import MyProfile from "pages/Authentication/MyProfile";
import EditProfile from "pages/Authentication/EditProfile";
import DueDiligenceSearch from "pages/DueDiligenceSearch";
import Contractors from "pages/Contractors";
import ViewContractors from "pages/Contractors/ViewContractors";
import Contracts from "pages/Contracts";
import Persons from "pages/Persons";
import AddPerson from "pages/Persons/AddPerson";
import ViewPerson from "pages/Persons/ViewPerson";
import RiskAssessments from "pages/Assessments/RiskAssessments";
import ViewRiskAssessment from "pages/Assessments/RiskAssessments/ViewRiskAssessment";
import SpotChecks from "pages/Assessments/SpotChecks";
import CapacityAssessments from "pages/Assessments/CapacityAssessments";
import PVRs from "pages/Assessments/PVRs";
import ViewPVRs from "pages/Assessments/PVRs/ViewPVRs";
import USSanctionsList from "pages/SanctionsList/USSanctionsList";
import USSanctionedItem from "pages/SanctionsList/USSanctionsList/ViewUSSanctionedItem";
import WBSanctionsList from "pages/SanctionsList/WBSanctionsList";
import WBSanctionedItem from "pages/SanctionsList/UNGMSanctionsList/ViewUNGMItem";
import EUSanctionsList from "pages/SanctionsList/EUSanctionsList";
import EUSanctionedItem from "pages/SanctionsList/EUSanctionsList/ViewEUSanctionedItem";
import UKSanctionsList from "pages/SanctionsList/UKSanctionsList";
import UKSanctionedItem from "pages/SanctionsList/UKSanctionsList/ViewUKSanctionedItem";
import UNGMSanctionsList from "pages/SanctionsList/UNGMSanctionsList";
import UNGMItem from "pages/SanctionsList/UNGMSanctionsList/ViewUNGMItem";
import CIMSReports from "pages/Reports/CIMSReports";
import SEMGReports from "pages/Reports/SEMGReports";
import GlobalUsers from "pages/Users";
import AgencyDashboard from "pages/Agency/Dashboard/AgencyDashboard";
import DueDiligenceRequests from "pages/Agency/DueDiligenceRequests/DueDiligenceRequests";
import AgencyContractors from "pages/Agency/Contractors/AgencyContractors";
import AgencyContracts from "pages/Agency/Contracts/AgencyContracts";
import ViewAgencyContract from "pages/Agency/Contracts/ViewAgencyContract";
import AgencyAssessments from "pages/Agency/Assessments/AgencyAssessments";
import AgencyPersons from "pages/Agency/Persons/AgencyPersons";
import AgencyUsers from "pages/Agency/Users/AgencyUsers";
import AllegationsReport from "pages/Reports/AllegationReports";
import ViewContract from "pages/Contracts/ViewContract";
import ContractsReport from "pages/Reports/ContractsReport";
import Countries from "pages/Countries";
import Agencies from "pages/Agencies";
import AddContractor from "pages/Contractors/AddContractor";
import UpdateContractor from "pages/Contractors/UpdateContractor";
import ViewCapacityAssessment from "pages/Assessments/CapacityAssessments/ViewCapacityAssessment";
import ViewUNAgencies from "pages/Agencies/UNAgencies";
import OrganizationTypes from "pages/DataTypes/OrganizationTypes";
import ContractTypes from "pages/DataTypes/ContractTypes";
import ContractRatings from "pages/DataTypes/ContractRatings";
import RiskAssessmentRatings from "pages/DataTypes/RiskAssessmentRatings";
import CapacityAssessmentTypes from "pages/DataTypes/CapacityAsssessmentTypes";
import CapacityAssessmentRatings from "pages/DataTypes/CapacityAssessmentRatings";
import Regions from "pages/Regions";
import LTAs from "pages/Contracts/LTAs";

const authProtectedRoutes = [
  //Dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/index", component: <Dashboard /> },

  // //transactions

  { path: "/due-diligence-search", component: <DueDiligenceSearch /> },
  { path: "/contractors", component: <Contractors /> },
  { path: "/contractors/:id", component: <ViewContractors /> },
  { path: "/contracts", component: <Contracts /> },
  { path: "/contracts/:id", component: <ViewContract /> },
  { path: "/agency-data/contracts/:id", component: <ViewAgencyContract /> },
  { path: "/persons", component: <Persons /> },
  { path: "/persons/add", component: <AddPerson /> },
  { path: "/persons/:id", component: <ViewPerson /> },
  { path: "/assessments/risk-assessments", component: <RiskAssessments /> },
  {
    path: "/assessments/risk-assessments/:id",
    component: <ViewRiskAssessment />,
  },
  { path: "/assessments/spot-checks", component: <SpotChecks /> },
  {
    path: "/assessments/capacity-assessments",
    component: <CapacityAssessments />,
  },
  {
    path: "/assessments/capacity-assessments/:id",
    component: <ViewCapacityAssessment />,
  },
  { path: "/assessments/pvrs", component: <PVRs /> },
  { path: "/assessments/pvrs/:id", component: <ViewPVRs /> },
  { path: "/sanctions-list/us", component: <USSanctionsList /> },
  { path: "/sanctions-list/us/:id", component: <USSanctionedItem /> },
  { path: "/sanctions-list/eu", component: <EUSanctionsList /> },
  { path: "/sanctions-list/eu/:id", component: <EUSanctionedItem /> },
  { path: "/sanctions-list/uk", component: <UKSanctionsList /> },
  { path: "/sanctions-list/uk/:id", component: <UKSanctionedItem /> },
  { path: "/sanctions-list/ungm", component: <UNGMSanctionsList /> },
  { path: "/sanctions-list/ungm/:id", component: <UNGMItem /> },
  { path: "/sanctions-list/world-bank", component: <WBSanctionsList /> },
  { path: "/sanctions-list/world-bank/:id", component: <WBSanctionedItem /> },
  { path: "/reports/cims-reports", component: <CIMSReports /> },
  { path: "/reports/semg-reports", component: <SEMGReports /> },
  { path: "/reports/contracts-report", component: <ContractsReport /> },

  { path: "/reports/allegations-report", component: <AllegationsReport /> },

  { path: "/users", component: <GlobalUsers /> },
  { path: "/agency/agency-dashboard/agency", component: <AgencyDashboard /> },
  {
    path: "/agency/due-diligence-requests",
    component: <DueDiligenceRequests />,
  },
  { path: "/agency-data/contractors", component: <AgencyContractors /> },
  { path: "/agency-data/contracts", component: <AgencyContracts /> },
  { path: "/agency-data/assessments", component: <AgencyAssessments /> },
  { path: "/agency-data/persons", component: <AgencyPersons /> },
  { path: "/agency/users", component: <AgencyUsers /> },
  { path: "/contractors/add", component: <AddContractor /> },
  { path: "/contractors/update/:id", component: <UpdateContractor /> },

  // Data Types
  { path: "/data-types/organization-types", component: <OrganizationTypes /> },
  { path: "/data-types/contract-types", component: <ContractTypes /> },
  { path: "/data-types/contract-ratings", component: <ContractRatings /> },
  {
    path: "/data-types/risk-assessment-ratings",
    component: <RiskAssessmentRatings />,
  },
  {
    path: "/data-types/capacity-assessment-types",
    component: <CapacityAssessmentTypes />,
  },
  {
    path: "/data-types/capacity-assessment-ratings",
    component: <CapacityAssessmentRatings />,
  },
  { path: "/ltas", component: <LTAs /> },

  //User Profile
  { path: "/profile", component: <MyProfile /> },
  { path: "/profile/edit", component: <EditProfile /> },

  // Admin Section
  { path: "/countries", component: <Countries /> },
  { path: "/regions", component: <Regions /> },
  { path: "/agencies", component: <Agencies /> },
  { path: "/un-agencies", component: <ViewUNAgencies /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/signup", component: <Register /> },
  { path: "/reset-password", component: <ResetPassword /> },
];

export { authProtectedRoutes, publicRoutes };
