import React from "react";
import { Label, FormFeedback } from "reactstrap";
import Select from "react-select";
import dataImporterOptions from "common/constants/data-importers";

const SelectImporter = ({
  importer,
  setImporter,
}: {
  importer: "";
  setImporter: (loading: any) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor: "#ced4da",
      "&:hover": {
        borderColor: "#ced4da",
      },
    }),
  };

  return (
    <>
      <Label htmlFor="contractRating-field" className="form-label">
        Select Importer
      </Label>
      <Select
        name="contractRating"
        id="contractRating"
        value={importer}
        placeholder="Select importer"
        onChange={(value: any) => {
          setImporter(value);
        }}
        options={dataImporterOptions}
        styles={customSelectStyles}
      />
      <FormFeedback></FormFeedback>
    </>
  );
};

export default SelectImporter;
