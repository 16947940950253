import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//import images
import OverviewTab from "./OverviewTab";
import ContractsTab from "./ContractsTab";
import AssessmentsTab from "./AssessmentsTab";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { getContractor as onGetContractor } from "slices/thunks";
import { createSelector } from "reselect";

const Content = ({ contractorId }: { contractorId: string }) => {
  const dispatch: any = useDispatch();

  const [activeTab, setActiveTab] = useState<any>("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (contractorId)
      dispatch(
        onGetContractor({
          id: contractorId,
        })
      );
  }, [contractorId, dispatch]);

  const selectProperties = createSelector(
    (state: any) => state.Contractors,
    (state) => ({
      contractor: state.contractor,
    })
  );
  const { contractor } = useSelector(selectProperties);

  const isDebarred = useMemo(() => {
    return contractor?.performanceRatings?.some(
      (rating: any) => rating?.performanceRatingType?.isFlag
    );
  }, [contractor]);

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {contractor?.semgMentions && contractor?.semgMentions?.length > 0 && (
            <Alert color="warning" className="material-shadow">
              <strong> SEMG Mention </strong> This contractor has been mentioned
              in an SEMG report. Please review the SEMG section below for more
              information.
            </Alert>
          )}
          {isDebarred && (
            <Alert color="danger" className="material-shadow">
              <strong> Suspended or Debarred! </strong> This contractor has been
              debarred by a UN Agency. Review the performance rating section
              below for more information.
            </Alert>
          )}
          <Card className="">
            <div className="bg-warning-subtle">
              <CardBody className="pb-0 px-4">
                <Header contractor={contractor} />
                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "1" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("1");
                      }}
                      href="#"
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "2" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("2");
                      }}
                      href="#"
                    >
                      Contracts
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "3" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("3");
                      }}
                      href="#"
                    >
                      Assessments
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "4" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("4");
                      }}
                      href="#"
                    >
                      Team
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <TabContent activeTab={activeTab} className="text-muted">
            <TabPane tabId="1">
              <OverviewTab contractor={contractor} toggleTab={toggleTab} />
            </TabPane>
            <TabPane tabId="2">
              <ContractsTab />
            </TabPane>
            <TabPane tabId="3">
              <AssessmentsTab />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Content;
