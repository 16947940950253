import { getContractDistributionByRegionAndType } from "common/services/contracts.service";
import getChartColorsArray from "Components/Common/ChartsDynamicColor";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import { createSelector } from "reselect";
import { setDistributionByRegionAndType } from "slices/contract/reducer";

const StackedChart = ({ data }: { data: any }) => {
  const dataColors =
    '["#3d78e3", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info", "--vz-secondary"]';

  var chartStackedBarColors = getChartColorsArray(dataColors);

  const contractTypes = Object.keys(data?.[0]?.contractTypes || {});

  // Convert to desired series format
  const series = contractTypes.map((type) => ({
    name: type,
    data: data.map(
      (regionData: { contractTypes: { [x: string]: any } }) =>
        regionData.contractTypes[type]
    ),
  }));

  const regionNames = data.map(
    (regionData: { region: any }) => regionData.region
  );

  //   const series = [
  //     {
  //       name: "Marine Sprite",
  //       data: [44, 55, 41, 37, 22, 43, 21],
  //     },
  //     {
  //       name: "Striking Calf",
  //       data: [53, 32, 33, 52, 13, 43, 32],
  //     },
  //     {
  //       name: "Tank Picture",
  //       data: [12, 17, 11, 9, 15, 11, 20],
  //     },
  //     {
  //       name: "Bucket Slope",
  //       data: [9, 7, 5, 8, 6, 9, 4],
  //     },
  //     {
  //       name: "Reborn Kid",
  //       data: [25, 12, 19, 32, 25, 24, 10],
  //     },
  //   ];
  console.log("Regions", regionNames);
  console.log("series", series);
  var options: any = {
    chart: {
      type: "bar",
      // stackType: "100%",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    title: {
      text: "",
      style: {
        fontWeight: 500,
      },
    },
    xaxis: {
      categories: regionNames,
      labels: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val.toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    colors: chartStackedBarColors,
    dataLabels: {
      formatter: function (val: number) {
        return `$ ${val.toLocaleString()}`;
      },
    },
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      options={options}
      series={series}
      type="bar"
      height={350}
    />
  );
};

const DistributionByRegionAndType = () => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const startDate = `${currentYear}-01-01`;
        const endDate = `${currentYear}-12-31`;
        const response = await getContractDistributionByRegionAndType({
          startDate,
          endDate,
        });

        console.log("response", response);

        dispatch(setDistributionByRegionAndType(response.data));
      } catch (error) {
        console.error("Error fetching contract distribution by region", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const selectProperties = createSelector(
    (state: any) => state,
    (state) => ({
      distributionByRegionAndType: state.Contracts.distributionByRegionAndType,
    })
  );

  const { distributionByRegionAndType } = useSelector(selectProperties);

  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader>
          <h4 className="card-title mb-0">
            Distribution of Contracts by Region and Contract Type
          </h4>
        </CardHeader>
        <CardBody>
          <StackedChart data={distributionByRegionAndType?.data || []} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DistributionByRegionAndType;
