import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateRegion } from "common/services/regions.service";
import RegionForm from "./RegionForm";
import { toast } from "react-toastify";

const UpdateRegion: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  selectedRecord: any;
}> = ({ toggle, isModalOpen, fetchData, selectedRecord }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedRecord?.name,
      altName1: selectedRecord?.altName1,
      level: {
        value: selectedRecord?.level,
        label: `${selectedRecord?.level}`,
      },
      country: {
        value: selectedRecord?.country?.id,
        label: `${selectedRecord?.country?.name}`,
      },
      adminLevel0: {
        value: selectedRecord?.adminLevel0?.id,
        label: `${selectedRecord?.adminLevel0?.name}`,
      },
      adminLevel1: {
        value: selectedRecord?.adminLevel1?.id,
        label: selectedRecord?.adminLevel1?.name,
      },
      x_coord: selectedRecord?.x_coord,
      y_coord: selectedRecord?.y_coord,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      altName1: Yup.string(),
      level: Yup.object().shape({
        value: Yup.number().required("Please select administration level"),
      }),
      country: Yup.object().shape({
        value: Yup.number().nullable(),
      }),
      adminLevel0: Yup.object().shape({
        value: Yup.number().nullable(),
      }),
      adminLevel1: Yup.object().shape({
        value: Yup.number().nullable(),
      }),
      x_coord: Yup.string(),
      y_coord: Yup.string(),
    }),
    onSubmit: async (values: any) => {
      const data = {
        id: selectedRecord?.id,
        data: {
          name: values["name"],
          altName1: values["altName1"],
          level: values["level"]["value"],
          adminLevel0: values["adminLevel0"]["value"] || null,
          adminLevel1: values["adminLevel1"]["value"] || null,
          x_coord: `${values["x_coord"]}`,
          y_coord: `${values["y_coord"]}`,
          country: values["country"]["value"] || null,
        },
      };

      setLoading(true);
      try {
        await updateRegion(data);
        fetchData();
        validation.resetForm();
        toggle();
        toast.success("Region updated successfully", {});
      } catch (e: any) {
        toast.error("Failed to update region", {});
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Region
      </ModalHeader>

      <RegionForm
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateRegion;
