import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Row, Table } from "reactstrap";
import { Link } from "react-router-dom";

import {
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  SortingState,
  Updater,
  PaginationState,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";
import Skeleton from "react-loading-skeleton";
import { CARD_MIN_HEIGHT } from "common/constants";

interface TableContainerProps {
  columns?: any;
  data?: any;
  handleTaskClick?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  pagination: any;
  setPagination?: Function;
  FilterSection: any;
  onPageChange: Function;
  loading?: boolean;
  totalRows?: number;
  totalPages?: number;
  searchValue?: string;
  filterValue?: any;
  sorted?: any;
  setSorted?: (updaterOrValue: Updater<SortingState>) => void;
}

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  pagination,
  setPagination,
  FilterSection,
  onPageChange,
  loading,
  totalRows,
  totalPages,
  searchValue,
  filterValue,
  sorted,
  setSorted,
}: TableContainerProps) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      pagination: pagination,
      sorting: sorted,
    },
    initialState: {
      sorting: [
        {
          id: "createdAt",
          desc: true,
        },
      ],
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    manualPagination: true,
    pageCount: totalPages || 0,
    autoResetPageIndex: false,
    autoResetAll: false,
    enableSorting: true,
    onSortingChange: setSorted as (
      updaterOrValue: Updater<SortingState>
    ) => void,
    onPaginationChange: setPagination as (
      updaterOrValue: Updater<PaginationState>
    ) => void,
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    setPageIndex,
    nextPage,
    previousPage,
    // setPageSize,
    getState,
    // setSorting,
  } = table;

  // Access pageIndex and pageSize
  const { pageIndex, pageSize } = getState().pagination;

  useEffect(() => {
    onPageChange({ page: pageIndex + 1, sorted, searchValue, filterValue });
  }, [pageIndex, pageSize, onPageChange, sorted, searchValue, filterValue]);

  // Render Custom page numbers that scale
  // Determine pages to be displayed in pagination
  const currentPage = pageIndex + 1;

  // @ts-ignore
  const startPage = Math.max(1, Math.min(currentPage - 2, totalPages - 4));

  // @ts-ignore
  const endPage = Math.min(totalPages, startPage + 4);

  const renderPageNumbers = useMemo(() => {
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <React.Fragment key={i}>
          <li className="page-item">
            <Link
              to="#"
              className={currentPage === i ? "page-link active" : "page-link"}
              // @ts-ignore
              onClick={() => setPageIndex(i - 1 || 0)}
            >
              {i}
            </Link>
          </li>
        </React.Fragment>
      );
    }
    return pageNumbers;
  }, [startPage, endPage, setPageIndex, currentPage]);

  return (
    <Fragment>
      {/* <>
        <FilterSection
          searchValue={searchValue}
          setSearchValue={(text: string) => {
            // if (pageIndex != 0) {
            //   gotoPage(0);
            // }
            setSearchValue(text);
          }}
        />
      </> */}

      <div className={divClass} style={{ minHeight: CARD_MIN_HEIGHT }}>
        <Table hover className={tableClass}>
          <thead className={theadClass}>
            {getHeaderGroups().map((headerGroup: any) => (
              <tr className={trClass} key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => {
                  // if (header.id === "name") {
                  //   console.log("Header", header);
                  //   console.log("Is Sorted", header.column.getSortIndex());
                  // }

                  return (
                    <th
                      key={header.id}
                      className={thClass}
                      onClick={() => {
                        if (!header.column.getCanSort()) return;
                        setPageIndex(0);
                        header.column.toggleSorting();
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          <div
                            style={{
                              display: "flex",
                              // justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {/* Column Title on the left */}
                            <span>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </span>

                            {header.column.getCanSort() ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginLeft: "5px",
                                  marginTop: "-12px",
                                }}
                              >
                                {{
                                  asc: (
                                    <>
                                      <i className="ri-arrow-up-s-fill text-muted table-container-sort-icon" />

                                      <i
                                        className="ri-arrow-down-s-fill text-muted table-container-sort-icon"
                                        style={{
                                          visibility: "hidden",
                                        }}
                                      />
                                    </>
                                  ),
                                  desc: (
                                    <>
                                      <i
                                        className="ri-arrow-up-s-fill table-container-sort-icon text-muted"
                                        style={{
                                          visibility: "hidden",
                                        }}
                                      />
                                      <i className="ri-arrow-down-s-fill table-container-sort-icon text-muted" />
                                    </>
                                  ),
                                }[header.column.getIsSorted() as string] ?? (
                                  <>
                                    <i className="ri-arrow-up-s-fill text-muted table-container-sort-icon" />
                                    <i className="ri-arrow-down-s-fill text-muted table-container-sort-icon" />{" "}
                                  </>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </React.Fragment>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {loading ? (
            <tbody style={{ minHeight: "300px" }}>
              {Array.from({ length: pageSize }).map((_, index) => (
                <tr key={index}>
                  {columns.map((column: any, colIndex: any) => (
                    <td key={colIndex}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {getRowModel().rows.map((row: any) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </div>

      <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing
            <span className="fw-semibold ms-1">
              {(data && data?.length) || 0}
            </span>{" "}
            of <span className="fw-semibold">{totalRows}</span> Results
          </div>
        </div>
        <div className="col-sm-auto">
          <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
            <li
              className={
                !getCanPreviousPage() ? "page-item disabled" : "page-item"
              }
            >
              <Link
                to="#"
                className="page-link"
                // @ts-ignore
                onClick={previousPage}
              >
                Previous
              </Link>
            </li>
            {/* {getPageOptions().map((item: any, key: number) => (
              <React.Fragment key={key}>
                <li className="page-item">
                  <Link
                    to="#"
                    className={
                      getState().pagination.pageIndex === item
                        ? "page-link active"
                        : "page-link"
                    }
                    onClick={() => setPageIndex(item)}
                  >
                    {item + 1}
                  </Link>
                </li>
              </React.Fragment>
            ))} */}

            {renderPageNumbers}
            <li
              className={!getCanNextPage() ? "page-item disabled" : "page-item"}
            >
              <Link
                to="#"
                className="page-link"
                // @ts-ignore
                onClick={nextPage}
              >
                Next
              </Link>
            </li>
          </ul>
        </div>
      </Row>
    </Fragment>
  );
};

export default TableContainer;
