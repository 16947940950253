import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getPerformanceRatings = (params: any) =>
  api.get(url.GET_PERFORMANCE_RATINGS, params);
export const getPerformanceRating = (id: any, params: any) =>
  api.get(url.GET_PERFORMANCE_RATING + "/" + id, params);
export const importPerformanceRating = (data: any) =>
  api.create(url.IMPORT_PERFORMANCE_RATING, data);

export const addPerformanceRating = (data: any) =>
  api.create(url.ADD_PERFORMANCE_RATING, data);
export const updatePerformanceRating = (data: any) =>
  api.update(url.UPDATE_PERFORMANCE_RATING + "/" + data.id, data);
export const deletePerformanceRating = (data: any) =>
  api.delete(url.DELETE_PERFORMANCE_RATING + "/" + data.id, data);

export const getPerformanceRatingTypes = (params: any) =>
  api.get(url.GET_PERFORMANCE_RATING_TYPES, params);
export const getPerformanceRatingType = (id: any, params: any) =>
  api.get(url.GET_PERFORMANCE_RATING_TYPE + "/" + id, params);
export const addPerformanceRatingType = (data: any) =>
  api.create(url.ADD_PERFORMANCE_RATING_TYPE, data);
export const updatePerformanceRatingType = (data: any) =>
  api.update(url.UPDATE_PERFORMANCE_RATING_TYPE + "/" + data.id, data);
export const deletePerformanceRatingType = (data: any) =>
  api.delete(url.DELETE_PERFORMANCE_RATING_TYPE + "/" + data.id, data);
