import React, { useState, useCallback, useEffect } from "react";
import { CardBody, Row, Col, Card, Container } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";

//Import actions
import { getAgencies } from "common/services/agencies.service";
import { getSortObj } from "common/lib/tableDataOptions";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const SkeletonLoading = () => {
  return (
    <Row className="row-cols-xl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
      {[...Array(15)].map((_, index) => (
        <Col key={index}>
          <Card className="explore-box card-animate">
            <div className="explore-place-bid-img">
              <div
                style={{
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fbfbfb",
                }}
              >
                <Skeleton width={190} height={100} />
              </div>
              <div className="bg-overlay"></div>
            </div>
            <CardBody className="border-top border-top-dashed">
              <h5 className="mb-1">
                <Skeleton width={100} />
              </h5>
              <p
                className="text-muted mb-0"
                style={{
                  minHeight: "3em",
                }}
              >
                <Skeleton count={2} />
              </p>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const Agencies = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      if (sorted.length === 0) {
        sorted.push({ id: "createdAt", desc: true });
      }

      const sortObj = getSortObj(sorted);

      try {
        const response: any = await getAgencies({
          pagination: {
            page,
            pageSize: 30,
          },
          sort: sortObj,
          populate: ["category", "mainLogo", "mainLogoLight"],
          filters: {
            $and: [
              {
                category: {
                  name: "UN",
                },
              },
              {
                name: {
                  $ne: "RMU",
                },
              },
            ],
          },
        });
        setData(normalize(response.data));
      } catch (error) {
        toast.error("Error while fetching data", {});
      } finally {
        setLoading(false);
      }
    },
    []
  );
  useEffect(() => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
    });
  }, [onPageChange]);

  document.title = "Agencies | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="UN Agencies"
            pageTitles={[
              {
                title: "Dashboard",
                link: "/",
              },
            ]}
            back
          />

          {loading ? (
            <SkeletonLoading />
          ) : (
            <Row className="row-cols-xl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
              {data.map((item: any, key) => (
                <Col key={item.id}>
                  <Card className="explore-box card-animate">
                    <div className="explore-place-bid-img">
                      <div
                        style={{
                          height: 200,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#fbfbfb",
                        }}
                      >
                        {item?.mainLogo?.formats?.thumbnail?.url && (
                          <img
                            src={`${process.env.REACT_APP_FILES_URL}${item?.mainLogo?.formats?.thumbnail?.url}`}
                            alt=""
                            height={
                              item?.mainLogoLightDimensions?.height || 100
                            }
                            width={item?.mainLogoLightDimensions?.width || 190}
                            className=""
                            style={{ objectFit: "contain" }}
                          />
                        )}
                      </div>
                      <div className="bg-overlay"></div>
                    </div>
                    <CardBody className="border-top border-top-dashed">
                      <h5 className="mb-1">
                        <Link to="#">{item?.name || ""}</Link>
                      </h5>
                      <p
                        className="text-muted mb-0"
                        style={{
                          minHeight: "3em",
                        }}
                      >
                        {item?.fullName || ""}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Agencies;
