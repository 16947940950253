// Auth
export const POST_JWT_LOGIN = "/auth/local";
export const POST_FORGET_PASSWORD = "/auth/forgot-password";
export const POST_CHANGE_PASSWORD = "/auth/change-password";
export const POST_REGISTER = "/auth/local/register";

export const SOCIAL_LOGIN = "/social-login";

// Countries
export const GET_COUNTRIES = "/countries";
export const GET_GLOBAL_COUNTRIES = "/global/countries";

// Agencies
export const GET_GLOBAL_AGENCIES = "/global-admin/agencies";
export const GET_AGENCIES = "/agencies";
export const ADD_AGENCY = "/agencies";
export const UPDATE_AGENCY = "/agencies";
export const DELETE_AGENCY = "/agencies";

// Country Offices
export const GET_COUNTRY_OFFICES = "/country-offices";
export const ADD_COUNTRY_OFFICE = "/country-offices";
export const UPDATE_COUNTRY_OFFICE = "/country-offices";
export const DELETE_COUNTRY_OFFICE = "/country-offices";

// Role
export const GET_ROLES = "/users-permissions/roles";
export const GET_ROLE = "/users-permissions/roles";

// Users
export const GET_USERS_DEFAULT = "/users";
export const GET_USERS = "/user-management/users";
export const GET_USER = "/user-management/users";
export const ADD_USER = "/user-management/users";
export const UPDATE_USER = "/user-management/users";
export const DELETE_USER = "/user-management/users";
export const ACTIVATE_USER = "/user-management/users/activate";
export const DEACTIVATE_USER = "/user-management/users/deactivate";
export const GET_USER_PROFILE = "/users/me";
export const UPDATE_MY_PROFILE = "/user-management/users/me";

export const GET_AGENCY_USERS = "/agency/user-management/users";
export const INVITE_USER = "/user-management/users/invite";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// Sanctions List
export const GET_EU_SANCTIONS_LIST = "/eu-sanctions-lists";
export const GET_EU_SANCTIONED_ITEM = "/eu-sanctions-lists";
export const GET_US_SANCTIONS_LIST = "/us-sanctions-lists";
export const GET_US_SANCTIONED_ITEM = "/us-sanctions-lists";
export const GET_UK_SANCTIONS_LIST = "/uk-sanctions-lists";
export const GET_UK_SANCTIONED_ITEM = "/uk-sanctions-lists";
export const GET_UNGM_LIST = "/ungm-lists";
export const GET_UNGM_ITEM = "/ungm-lists";
export const UPLOAD_UNGM_LIST = "/ungm-lists/upload-content";

// Contracts
export const GET_CONTRACTS = "/contracts";
export const GET_CONTRACT = "/contracts";
export const ADD_CONTRACT = "/contracts";
export const UPDATE_CONTRACT = "/contracts";
export const DELETE_CONTRACT = "/contracts";
export const IMPORT_CONTRACT = "/contracts/import";

export const GET_CONTRACT_DISTRIBUTION_BY_TYPE =
  "/contracts/stats/distribution-by-type";
export const GET_COUNTRY_CONTRACTS_SUMMARY = "/country/contracts/summary";
export const GET_CONTRACT_DISTRIBUTION_BY_AGENCY =
  "/contracts/stats/distribution-by-agency";
export const GET_CONTRACT_TOTALS = "/contracts/stats/totals";
export const GET_CONTRACT_DISTRIBUTION_BY_REGION =
  "/contracts/stats/distribution-by-region";
export const GET_CONTRACT_DISTRIBUTION_BY_REGION_TYPE =
  "/contracts/stats/distribution-by-region-type";

export const GET_AGENCY_CONTRACTS = "/agency/contracts";
export const GET_AGENCY_CONTRACT = "/agency/contracts";
export const ADD_AGENCY_CONTRACT = "/agency/contracts";
export const UPDATE_AGENCY_CONTRACT = "/agency/contracts";
export const DELETE_AGENCY_CONTRACT = "/agency/contracts";
export const GET_AGENCY_ACTIVE_CONTRACT_VALUE =
  "/agency/contracts/active-contract-value";
export const GET_AGENCY_CONTRACT_DISTRIBUTION_BY_TYPE =
  "/contracts/distribution-by-type";

// Contractors
export const GET_CONTRACTORS = "/contractors";
export const GET_CONTRACTOR = "/contractors";
export const ADD_CONTRACTOR = "/contractors";
export const UPDATE_CONTRACTOR = "/contractors";
export const DELETE_CONTRACTOR = "/contractors";
export const IMPORT_CONTRACTOR = "/contractors/import";

export const GET_AGENCY_CONTRACTORS = "/agency/contractors";
export const GET_AGENCY_CONTRACTOR = "/agency/contractors";
export const ADD_AGENCY_CONTRACTOR = "/agency/contractors";
export const UPDATE_AGENCY_CONTRACTOR = "/agency/contractors";
export const DELETE_AGENCY_CONTRACTOR = "/agency/contractors";

export const GET_PERFORMANCE_RATINGS = "/performance-ratings";
export const GET_PERFORMANCE_RATING = "/performance-ratings";
export const IMPORT_PERFORMANCE_RATING = "/performance-ratings/import";
export const ADD_PERFORMANCE_RATING = "/performance-ratings";
export const UPDATE_PERFORMANCE_RATING = "/performance-ratings";
export const DELETE_PERFORMANCE_RATING = "/performance-ratings";

export const GET_AGENCY_CONTRACTOR_RATINGS = "/agency/contractor-ratings";
export const GET_AGENCY_CONTRACTOR_RATING = "/agency/contractor-ratings";
export const ADD_AGENCY_CONTRACTOR_RATING = "/agency/contractor-ratings";
export const UPDATE_AGENCY_CONTRACTOR_RATING = "/agency/contractor-ratings";
export const DELETE_AGENCY_CONTRACTOR_RATING = "/agency/contractor-ratings";

export const GET_PERFORMANCE_RATING_TYPES = "/performance-rating-types";
export const GET_PERFORMANCE_RATING_TYPE = "/performance-rating-types";
export const ADD_PERFORMANCE_RATING_TYPE = "/performance-rating-types";
export const UPDATE_PERFORMANCE_RATING_TYPE = "/performance-rating-types";
export const DELETE_PERFORMANCE_RATING_TYPE = "/performance-rating-types";

export const GET_CONTRACTOR_SUMMARY = "/country/contractors/summary";

export const UPDATE_CONTRACTOR_SEMG_MENTION = "/contractors";
export const UPDATE_CONTRACTOR_PERFORMANCE_RATING = "/contractors";
export const UPDATE_CONTRACTOR_BOARD_OF_DIRECTORS = "/contractors";
export const UPDATE_CONTRACTOR_AFFILIATIONS_TO_PERSON = "/contractors";
export const UPDATE_CONTRACTOR_AFFILIATIONS_TO_CONTRACTOR = "/contractors";

// Contract Ratings
export const GET_CONTRACT_RATINGS = "/contract-ratings";
export const GET_CONTRACT_RATING = "/contract-ratings";
export const ADD_CONTRACT_RATING = "/contract-ratings";
export const UPDATE_CONTRACT_RATING = "/contract-ratings";
export const DELETE_CONTRACT_RATING = "/contract-ratings";

// Contract Types
export const GET_CONTRACT_TYPES = "/contract-types";
export const GET_CONTRACT_TYPE = "/contract-types";
export const ADD_CONTRACT_TYPE = "/contract-types";
export const UPDATE_CONTRACT_TYPE = "/contract-types";
export const DELETE_CONTRACT_TYPE = "/contract-types";

// Risk Assessments
export const GET_RISK_ASSESSMENTS = "/risk-assessments";
export const GET_RISK_ASSESSMENT = "/risk-assessments";
export const ADD_RISK_ASSESSMENT = "/risk-assessments";
export const UPDATE_RISK_ASSESSMENT = "/risk-assessments";
export const DELETE_RISK_ASSESSMENT = "/risk-assessments";
export const GET_RESTRICTED_FILES = "/risk-assessments";
export const GET_CONFIDENTIAL_FILES = "/risk-assessments";

// Capacity Assessments
export const GET_CAPACITY_ASSESSMENTS = "/capacity-assessments";
export const GET_CAPACITY_ASSESSMENT = "/capacity-assessments";
export const ADD_CAPACITY_ASSESSMENT = "/capacity-assessments";
export const UPDATE_CAPACITY_ASSESSEMENT = "/capacity-assessments";
export const DELETE_CAPACITY_ASSESSMENT = "/capacity-assessments";

export const GET_AGENCY_CAPACITY_ASSESSMENTS = "/agency/capacity-assessments";
export const GET_AGENCY_CAPACITY_ASSESSMENT = "/agency/capacity-assessments";
export const ADD_AGENCY_CAPACITY_ASSESSMENT = "/agency/capacity-assessments";
export const UPDATE_AGENCY_CAPACITY_ASSESSEMENT =
  "/agency/capacity-assessments";
export const DELETE_AGENCY_CAPACITY_ASSESSMENT = "/agency/capacity-assessments";

// Capacity Assessment Types
export const GET_CAPACITY_ASSESSMENT_TYPE = "/capacity-assessment-types";
export const GET_CAPACITY_ASSESSMENT_TYPES = "/capacity-assessment-types";
export const ADD_CAPACITY_ASSESSMENT_TYPE = "/capacity-assessment-types";
export const UPDATE_CAPACITY_ASSESSMENT_TYPE = "/capacity-assessment-types";
export const DELETE_CAPACITY_ASSESSMENT_TYPE = "/capacity-assessment-types";

// Capacity Assessment Ratings
export const GET_CAPACITY_ASSESSMENT_RATINGS = "/capacity-assessment-ratings";
export const GET_CAPACITY_ASSESSMENT_RATING = "/capacity-assessment-ratings";
export const ADD_CAPACITY_ASSESSMENT_RATING = "/capacity-assessment-ratings";
export const UPDATE_CAPACITY_ASSESSMENT_RATING = "/capacity-assessment-ratings";
export const DELETE_CAPACITY_ASSESSMENT_RATING = "/capacity-assessment-ratings";

// Risk Ratings
export const GET_RISK_RATINGS = "/risk-assessment-ratings";
export const GET_RISK_RATING = "/risk-assessment-ratings";
export const ADD_RISK_RATING = "/risk-assessment-ratings";
export const UPDATE_RISK_RATING = "/risk-assessment-ratings";
export const DELETE_RISK_RATING = "/risk-assessment-ratings";

// PVRs
export const GET_PVRS = "/partner-verification-reports";
export const GET_PVR = "/partner-verification-reports";
export const ADD_PVR = "/partner-verification-reports";
export const UPDATE_PVR = "/partner-verification-reports";
export const DELETE_PVR = "/partner-verification-reports";

export const GET_AGENCY_PVRS = "/agency/partner-verification-reports";
export const GET_AGENCY_PVR = "/agency/partner-verification-reports";
export const ADD_AGENCY_PVR = "/agency/partner-verification-reports";
export const UPDATE_AGENCY_PVR = "/agency/partner-verification-reports";
export const DELETE_AGENCY_PVR = "/agency/partner-verification-reports";

// Organization Types
export const GET_ORGANIZATION_TYPES = "/organization-types";
export const GET_ORGANIZATION_TYPE = "/organization-types";
export const ADD_ORGANIZATION_TYPE = "/organization-types";
export const UPDATE_ORGANIZATION_TYPE = "/organization-types";
export const DELETE_ORGANIZATION_TYPE = "/organization-types";

// Search
export const FUZZY_SEARCH = "/fuzzy-search/search";

// Persons
export const GET_PERSONS = "/persons";
export const GET_PERSON = "/persons";
export const ADD_PERSON = "/persons";
export const UPDATE_PERSON = "/persons";
export const DELETE_PERSON = "/persons";

export const UPDATE_PERSON_SEMG_MENTION = "/persons";
export const UPDATE_PERSON_AFFILIATIONS_TO_CONTRACTOR = "/persons";
export const UPDATE_PERSON_AFFILIATIONS_TO_PERSON = "/persons";

// SEMG Mentions
export const GET_SEMG_MENTIONS = "/semg-mentions";
export const GET_SEMG_MENTION = "/semg-mentions";
export const ADD_SEMG_MENTION = "/semg-mentions";
export const UPDATE_SEMG_MENTION = "/semg-mentions";
export const DELETE_SEMG_MENTION = "/semg-mentions";

// Regions
export const GET_REGIONS = "/regions";
export const GET_REGION = "/regions";
export const ADD_REGION = "/regions";
export const UPDATE_REGION = "/regions";
export const DELETE_REGION = "/regions";
