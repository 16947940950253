import React, { useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

import { createSelector } from "reselect";
import Permission from "Components/Common/Permission";
import { getSortObj } from "common/lib/tableDataOptions";
import { getPersons as onGetPersons } from "slices/thunks";
import { LEVEL_COUNTRY } from "common/constants";
import moment from "moment";
import { deletePerson } from "common/services/persons.service";
import { toast } from "react-toastify";
import DeleteModal from "Components/Common/DeleteModal";

interface FilterValue {
  gender: string;
}

const AllPersons = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const selectProperties = createSelector(
    (state: any) => state.Persons,
    (state) => ({
      persons: state.persons.data,
      meta: state.persons.meta,
      error: state.error,
      loading: state.loading,
    })
  );
  const { persons, meta, loading } = useSelector(selectProperties);

  const [deleteModal, setDeleteModal] = useState(false);

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    gender: "",
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: FilterValue;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions: any = [];

      if (filterValue?.gender) {
        andConditions.push({
          gender: {
            $eq: filterValue.gender,
          },
        });
      }

      andConditions.push({
        $or: [
          {
            firstName: {
              $containsi: searchValue,
            },
          },
          {
            surname: {
              $containsi: searchValue,
            },
          },
          {
            middleName: {
              $containsi: searchValue,
            },
          },
        ],
      });

      dispatch(
        onGetPersons({
          level: LEVEL_COUNTRY,
          params: {
            pagination: {
              page,
              pageSize: pagination.pageSize,
            },
            sort: sortObj,
            populate: [
              "alias",
              "contactDetails",
              "flaggedBy",
              "createdby",
              "updatedby",
              "currentNationality",
            ],
            filters: {
              $and: andConditions,
            },
          },
        })
      );
    },
    [dispatch, pagination.pageSize, searchParams, setSearchParams]
  );

  const onClickDelete = useCallback(
    (data: any) => {
      setSelectedRecord(data);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedData = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, searchValue, sorted]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deletePerson(selectedRecord);
        fetchUpdatedData();
        toast.success("Person deleted successfully", {});
      } catch (error) {
        toast.error("Failed to delete person", {});
      } finally {
        setDeleting(false);
        setDeleteModal(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "firstName",
        id: "firstName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;

          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 avatar-xs me-3">
                  <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-16">
                    <i className={"ri-user-line"}></i>
                  </div>
                </div>
                <Link
                  to={`/persons/${cell.row.original?.id}`}
                  className="fw-medium link-primary"
                >
                  {`${rowData?.firstName || ""} ${rowData?.middleName || ""} ${
                    rowData?.surname || ""
                  }`}
                </Link>
              </div>
            </>
          );
        },
      },
      {
        header: "Gender",
        accessorKey: "gender",
        enableColumnFilter: false,
      },
      {
        header: "Nationality",
        accessorKey: "currentNationality.name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Date Added",
        accessorKey: "createdAt",
        id: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission resource="person" action="findOne">
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/persons/${rowData.id}`);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </DropdownItem>
                </Permission>

                <Permission resource="person" action="delete">
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [navigate, onClickDelete]
  );
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row className="g-3">
            <Col xxl={7} lg={6}>
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search people..."
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setPage(1);
                  }}
                  value={searchValue}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </Col>
            <Col xxl={3} lg={6}>
              <select
                className="form-control"
                data-choices
                data-choices-search-false
                name="choices-single-default2"
                id="choices-single-default2"
                onChange={(e: any) => {
                  setFilterValue({ ...filterValue, gender: e.target.value });
                  setPage(1);
                }}
                value={filterValue.gender}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </Col>

            <Col xxl={2} lg={4}>
              <button
                onClick={() => {
                  setFilterValue(defaultFilterValue);
                  setSearchValue("");
                  setPage(1);
                  setSorted([{ id: "createdAt", desc: true }]);
                }}
                className="btn btn-outline-primary material-shadow-none w-100"
              >
                <i className="ri-equalizer-line align-bottom me-1"></i> Reset
                Filters
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">Persons</h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <Permission resource="person" action="create">
                  <Link to="/persons/add" className="btn btn-primary">
                    <i className="ri-add-line align-bottom me-1"></i>
                    Add Person
                  </Link>
                </Permission>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            loading={loading}
            columns={columns}
            data={persons || []}
            pagination={pagination}
            setPagination={setPagination}
            sorted={sorted}
            setSorted={setSorted}
            onPageChange={onPageChange}
            FilterSection={() => {}}
            totalRows={meta?.pagination?.total || 0}
            totalPages={meta?.pagination?.pageCount || 0}
            searchValue={searchValue}
            filterValue={filterValue}
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light table-nowrap"
          />
        </CardBody>
      </Card>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setDeleteModal(false)}
        loading={deleting}
      />
    </React.Fragment>
  );
};

export default AllPersons;
