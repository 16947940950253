const dataImporters: any = [
  {
    label: "Contractors",
    value: "contractors",
  },
  {
    label: "Contracts",
    value: "contracts",
  },
  {
    label: "Performance Ratings",
    value: "performance-ratings",
  },
  // {
  //   label: "Persons",
  //   value: "persons",
  // },
  // {
  //   label: "Risk Assessments",
  //   value: "risk-assessments",
  // },
  // {
  //   label: "Capacity Assessments",
  //   value: "capacity-assessments",
  // },
  // {
  //   label: "Partner Verification Reports",
  //   value: "partner-verification-reports",
  // },
  // {
  //   label: "SEMG Mentions",
  //   value: "semg-mentions",
  // },

  // {
  //   label: "Board of Directors",
  //   value: "board-of-directors",
  // },
];

export default dataImporters;
