import React from "react";
import {
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";

interface PhoneNumberInputProps {
  validation: any;
}

const PhoneNumberInput = ({ validation }: PhoneNumberInputProps) => {
  return (
    <>
      <Card className="bg-light">
        <CardBody>
          {validation.values.phoneNumber.map((name: string, index: number) => (
            <div key={index} className="my-2">
              {/* <Label htmlFor={`phoneNumber-${index}`} className="form-label">
                  Phone Number
                </Label> */}
              <Row>
                <Col xl="11" className="mb-3 mb-xl-0">
                  <Input
                    name={`phoneNumber.${index}`}
                    id={`phoneNumber.${index}`}
                    className="form-control"
                    placeholder="Enter phone number"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={name}
                    invalid={
                      validation.touched.phoneNumber &&
                      validation.touched.phoneNumber[index] &&
                      validation.errors.phoneNumber &&
                      validation.errors.phoneNumber[index]
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phoneNumber &&
                  validation.touched.phoneNumber[index] &&
                  validation.errors.phoneNumber &&
                  validation.errors.phoneNumber[index] ? (
                    <FormFeedback type="invalid">
                      {validation.errors?.phoneNumber &&
                        validation.errors.phoneNumber[index]}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col xl="1">
                  <Button
                    className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none w-100 h-100"
                    onClick={() => {
                      const names = validation.values.phoneNumber;
                      names.splice(index, 1);
                      validation.setFieldValue("phoneNumber", names);
                    }}
                  >
                    {/* <i className="ri-delete-bin-fill me-2"></i> */}
                    Remove
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
        </CardBody>
      </Card>
      <button
        className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
        onClick={(e) => {
          e.preventDefault();
          if (validation.values.phoneNumber.length < 5) {
            validation.setFieldValue("phoneNumber", [
              ...validation.values.phoneNumber,
              "",
            ]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default PhoneNumberInput;
