import { normalize } from "common/lib/normalize";
import { getAgencies } from "common/services/agencies.service";
import React, { useEffect, useMemo } from "react";

type AgencyFilterProps = {
  onChange: (value: any) => void;
  value: any;
};

const AgencyFilter = ({ onChange, value }: AgencyFilterProps) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getAgencies({
          pagination: {
            page: 1,
            pageSize: 10000,
          },
          filters: {
            category: {
              $null: false,
            },
          },
        });
        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [data]);

  return (
    <select
      className="form-control"
      data-choices
      data-choices-search-false
      name="choices-single-default"
      id="choices-single-default"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    >
      <option value="">Select Agency </option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default AgencyFilter;
