import React from "react";
import ImportokWizard from "@importok/react";
import { addContract } from "common/services/contracts.service";

const BoardOfDirectorsImport: React.FC<{}> = () => {
  const fields = {
    contractor: {
      label: "Contractor",
      validators: "",
      transformers: "trim",
    },
    person: {
      label: "Person",
      validators: "",
      transformers: "trim",
    },
    role: {
      label: "Role",
      validators: "",
      transformers: "trim",
    },
    occupation: {
      label: "Occupation",
      validators: "",
      transformers: "trim",
    },
    department: {
      label: "Department",
      validators: "",
      transformers: "trim",
    },
    fromDate: {
      label: "From Date",
      validators: "",
      transformers: "trim",
    },
    toDate: {
      label: "To Date",
      validators: "",
      transformers: "trim",
    },
  };

  const transformers = {};

  const validators = {};

  const providers = {
    // contractor: {
    //   get: async function (query: any) {
    //     console.log("Query", query);
    //     const response = await getContractors({
    //       query,
    //     });
    //     console.log("Response", response);
    //     if (!response.data) {
    //       throw new Error(`Response status ${response.status}`);
    //     }

    //     const data = await response.data;
    //     return {
    //       value: "Test Found",
    //       label: "Test Found",
    //     };
    //   },
    //   find: async function (query: any) {
    //     console.log("Query", query);
    //     const response = await getContractors({
    //       query,
    //     });
    //     console.log("Response", response);
    //     if (!response.data) {
    //       throw new Error(`Response status ${response.status}`);
    //     }

    //     const data = await response.data;
    //     return {
    //       value: "Test Found",
    //       label: "Test Found",
    //     };
    //   },
    // },
    restcountries: {
      get: async function (query: any) {
        const response = await fetch(
          "https://restcountries.com/v3.1/alpha/" + query + "?fields=name,cca2"
        );
        if (!response.ok) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await response.json();
        return {
          value: data.cca2,
          label: data.name.common,
        };
      },
      find: async function (query: any) {
        const response = await fetch(
          "https://restcountries.com/v3.1/name/" + query + "?fields=name,cca2"
        );
        if (!response.ok) {
          throw new Error(`Response status ${response.status}`);
        }

        const data = await response.json();
        return data.map((item: any) => ({
          value: item.cca2,
          label: item.name.common,
        }));
      },
    },
  };

  const saveRecord = async function (record: any, meta: any) {
    return await addContract({
      data: {
        contractTitle: record?.properties?.contractTitle,
      },
    });
  };

  return (
    <ImportokWizard
      title="Import Board of Directors / Staff"
      fields={fields}
      transformers={transformers}
      validators={validators}
      providers={providers}
      onRecordReady={saveRecord}
    />
  );
};

export default BoardOfDirectorsImport;
