import React, { useState, useMemo, useCallback } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
import * as moment from "moment";

//Import actions
import { getAgencies, deleteAgency } from "common/services/agencies.service";
import AddAgency from "./AddAgency";
import UpdateAgency from "./UpdateAgency";
import { getSortObj } from "common/lib/tableDataOptions";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";

interface FilterValue {
  category: string;
}

const FilterSection: React.FC<{
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}> = ({ searchValue, setSearchValue }) => {
  return (
    <Row className="mb-3">
      <React.Fragment>
        <CardBody className="border border-dashed border-end-0 border-start-0">
          <form>
            <Row>
              <Col sm={5}>
                <div className={"search-box me-2 mb-2 d-inline-block col-12"}>
                  <input
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control search /"
                    placeholder={"Search agencies"}
                    value={searchValue || ""}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>

              {searchValue.length > 0 ? (
                <Col sm={4} xxl={1}>
                  <Button
                    color="primary"
                    className="w-400"
                    style={{ paddingInline: 10 }}
                    onClick={() => setSearchValue("")}
                  >
                    <i className="ri-close-fill me-1 align-bottom"></i>
                    Filters
                  </Button>
                </Col>
              ) : null}
            </Row>
          </form>
        </CardBody>
      </React.Fragment>
    </Row>
  );
};

const Agencies = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [deleteModal, setDeleteModal] = useState(false);

  const fetchAgencies = () => {
    setPage(1);
    setSorted([{ id: "createdAt", desc: true }]);
    setSearchValue("");
    setFilterValue(defaultFilterValue);
  };

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    category: "",
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: FilterValue;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      try {
        const response: any = await getAgencies({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: ["category", "mainLogoLight", "mainLogo"],
          filters: {
            $or: [
              {
                name: {
                  $containsi: searchValue,
                },
              },
            ],
            // $and: [
            //   {
            //     category: {
            //       $notNull: true,
            //     },
            //   },
            // ],
          },
        });
        setData(normalize(response.data));
        setMeta(response?.meta);
      } catch (error) {
        toast.error("Error while fetching data", {});
      } finally {
        setLoading(false);
      }
    },
    [filterValue, pagination.pageSize, searchParams, setSearchParams]
  );

  const fetchUpdatedAgencies = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, sorted, searchValue]);

  // Delete Data
  const onClickDelete = (agency: any) => {
    setSelectedRecord(agency);
    setDeleteModal(true);
  };
  const [deleting, setDeleting] = useState<any>(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteAgency(selectedRecord);
        fetchUpdatedAgencies();
        setDeleteModal(false);
        toast.success("Agency deleted successfully", {});
      } catch (error) {
        toast.error("Error while deleting agency", {});
      } finally {
        setDeleting(false);
      }
    }
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (agency: any) => {
    setSelectedRecord(agency);
    setIsUpdateModalOpen(true);
  };

  //Column
  const columns = useMemo(
    () => [
      {
        header: "Agency Name",
        accessorKey: "name",
        id: "name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.getValue()}
            </Link>
          );
        },
      },
      {
        header: "Full Name",
        accessorKey: "fullName",
        id: "fullName",
        enableColumnFilter: false,
      },
      {
        header: "Category",
        accessorKey: "category.name",
        id: "category.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.getValue()}
            </Link>
          );
        },
      },
      {
        header: "Main Logo",
        accessorKey: "mainLogo",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;

          return (
            <>
              {rowData?.mainLogo?.formats?.thumbnail?.url ? (
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 img-thumbnail rounded-circle">
                    <img
                      src={`${process.env.REACT_APP_FILES_URL}${rowData?.mainLogo?.formats?.thumbnail?.url}`}
                      alt=""
                      className="avatar-xs rounded-circle"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
              ) : (
                <p className="">-</p>
              )}
            </>
          );
        },
      },
      {
        header: "Light Logo",
        accessorKey: "mainLogoLight",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;

          return (
            <>
              {rowData?.mainLogoLight?.formats?.thumbnail?.url ? (
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 img-thumbnail rounded-circle">
                    <img
                      src={`${process.env.REACT_APP_FILES_URL}${rowData?.mainLogoLight?.formats?.thumbnail?.url}`}
                      alt=""
                      className="avatar-xs rounded-circle"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
              ) : (
                <p className="">-</p>
              )}
            </>
          );
        },
      },

      {
        header: "Last Updated At",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");

          return <>{date}</>;
        },
      },

      {
        header: "Action",
        id: "action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" end>
                <DropdownItem
                  href="#"
                  onClick={() =>
                    showUpdateModalForm({
                      id: rowData.id,
                      ...rowData,
                    })
                  }
                >
                  <i className="ri-edit-2-line align-bottom me-2 text-muted"></i>{" "}
                  Edit
                </DropdownItem>

                <DropdownItem divider />

                <DropdownItem
                  href="#"
                  onClick={() => {
                    onClickDelete({
                      id: rowData.id,
                      ...rowData,
                    });
                  }}
                >
                  <i className="ri-delete-bin-line align-bottom me-2 text-muted"></i>{" "}
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  document.title = "Agencies | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => handleDelete()}
          onCloseClick={() => setDeleteModal(false)}
          loading={deleting}
        />

        <Container fluid>
          <BreadCrumb title="Agencies" pageTitle="Dashboard" />

          <Row>
            <Col lg={12}>
              <Card id="agencyList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Agencies</h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            showAddModalForm();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add Agency
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <Row className="mb-3">
                    <React.Fragment>
                      <CardBody className="border border-dashed border-end-0 border-start-0">
                        <form>
                          <Row>
                            <Col sm={5}>
                              <div
                                className={
                                  "search-box me-2 mb-2 d-inline-block col-12"
                                }
                              >
                                <input
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    setPage(1);
                                  }}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control search /"
                                  placeholder={"Search agencies"}
                                  value={searchValue || ""}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </Col>

                            {searchValue.length > 0 ? (
                              <Col sm={4} xxl={1}>
                                <Button
                                  color="primary"
                                  className="w-400"
                                  style={{ paddingInline: 10 }}
                                  onClick={() => {
                                    setSearchValue("");
                                    setPage(1);
                                    setFilterValue(defaultFilterValue);
                                    setSorted([
                                      { id: "createdAt", desc: true },
                                    ]);
                                  }}
                                >
                                  <i className="ri-close-fill me-1 align-bottom"></i>
                                  Filters
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </form>
                      </CardBody>
                    </React.Fragment>
                  </Row>
                  <div>
                    <TableContainer
                      loading={loading}
                      columns={columns}
                      data={data || []}
                      pagination={pagination}
                      setPagination={setPagination}
                      sorted={sorted}
                      setSorted={setSorted}
                      onPageChange={onPageChange}
                      FilterSection={() => {}}
                      totalRows={meta?.pagination?.total || 0}
                      totalPages={meta?.pagination?.pageCount || 0}
                      searchValue={searchValue}
                      filterValue={filterValue}
                      divClass="table-responsive table-card mb-4"
                      tableClass="align-middle table-nowrap mb-0"
                      theadClass="table-light table-nowrap"
                    />
                  </div>
                  <AddAgency
                    toggle={() => setIsAddModalOpen((state) => !state)}
                    isModalOpen={isAddModalOpen}
                    fetchAgencies={fetchAgencies}
                  />
                  <UpdateAgency
                    toggle={() => setIsUpdateModalOpen((state) => !state)}
                    isModalOpen={isUpdateModalOpen}
                    selectedRecord={selectedRecord}
                    fetchAgencies={fetchUpdatedAgencies}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Agencies;
