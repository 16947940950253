import React, { useEffect, useMemo, useState } from "react";

type LevelFilterProps = {
  onChange: (value: any) => void;
  value: any;
};

const LevelFilter = ({ onChange, value }: LevelFilterProps) => {
  const [loading, setLoading] = useState(false);

  const options = useMemo(() => {
    return [
      {
        value: 0,
        label: "0",
      },
      {
        value: 1,
        label: "1",
      },
      {
        value: 2,
        label: "2",
      },
    ];
  }, []);

  return (
    <select
      className="form-control"
      data-choices
      data-choices-search-false
      name="choices-single-default2"
      id="choices-single-default2"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    >
      {loading && <option>Loading...</option>}
      <option value="">Select level</option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default LevelFilter;
