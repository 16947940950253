import React, { useEffect, useMemo } from "react";
import { Label, Input, FormFeedback, FormGroup } from "reactstrap";
import { getAgencies } from "common/services/agencies.service";
import { normalize } from "common/lib/normalize";

type SelectAgencyCategoryProps = {
  validation: any;
};

const SelectAgencyCategory = ({ validation }: SelectAgencyCategoryProps) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getAgencies({
          pagination: {
            page: 1,
            pageSize: 10000,
          },
          filters: {
            category: {
              $null: true,
            },
          },
        });
        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label htmlFor="choices-publish-status-input" className="form-label">
        Select Category
      </Label>
      <Input
        name="category"
        type="select"
        className="form-select"
        id="choices-publish-body-input"
        placeholder="Select year of manufacture"
        onChange={(e) => {
          validation.setFieldValue("category", e.target.value);
        }}
        onBlur={() => validation.setFieldTouched("category", true)}
        value={validation.values.category || ""}
        loading={loading}
        invalid={
          validation.touched.category && validation.errors.category
            ? true
            : false
        }
      >
        <option value="" selected>
          Select an option
        </option>
        {options.map((option: any) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
      {validation.touched.category && validation.errors.category ? (
        <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
      ) : null}
    </FormGroup>
  );
};

export default SelectAgencyCategory;
