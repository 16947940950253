import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateContractRating } from "common/services/contracts.service";
import Form from "./Form";
import { toast } from "react-toastify";

const UpdateContractRating: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  selectedRecord: any;
}> = ({ toggle, isModalOpen, fetchData, selectedRecord }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: selectedRecord?.name,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
    }),
    onSubmit: async (values: any) => {
      const data = {
        id: selectedRecord?.id,
        data: {
          name: values["name"],
        },
      };

      setLoading(true);
      try {
        await updateContractRating(selectedRecord?.id, data);
        fetchData();
        validation.resetForm();
        toggle();
        toast.success("Contract rating updated successfully", {});
      } catch (e: any) {
        toast.error("Contract rating update failed", {});
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contract Rating
      </ModalHeader>

      <Form
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
      />
    </Modal>
  );
};

export default UpdateContractRating;
